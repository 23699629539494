import { useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useAuth } from "../components/auth";
import { useForm } from "react-hook-form";

const Login = () => {
  const location = useLocation();
  const auth = useAuth();

  const { register, handleSubmit } = useForm();

  const callBackUrl = new URLSearchParams(location.search).get("callbackUrl"); //get callbackurl from url query string.

  const { from } = location.state || { from: { pathname: "/" } };

  if (auth.user) {
    if (callBackUrl) {
      window.location.replace(callBackUrl);
    } else {
      return <Redirect to={from} />;
    }
  }

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-28 w-auto"
          src="https://www.egateway.co/wp-content/uploads/2018/03/favaicon.png"
          alt="EGS"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Login to your account
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form
            className="space-y-6"
            onSubmit={handleSubmit(async (data) => {
              await auth.signin(data);
              if (callBackUrl) {
                window.location.replace(callBackUrl); //redirect if callbackurl presents. Usually passed from verification email.
              }
            })}
          >
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email:
              </label>
              <input
                {...register("email")}
                id="email"
                type="email"
                autoComplete="username"
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password:
              </label>
              <input
                {...register("password")}
                id="password"
                type="password"
                autoComplete="current-password"
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              />
            </div>
            <label
              htmlFor="remember"
              className="block text-sm font-medium text-gray-700"
            >
              <input id="remember" type="checkbox" {...register("remember")} />
              <span className="ml-2">Remember Me</span>
            </label>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium capitalize text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
