import { RefreshIcon, UploadIcon } from "@heroicons/react/outline";
import { Form, Formik } from "formik";
import { gql } from "graphql-request";
import { isEmpty } from "ramda";
import { useState } from "react";
import { mutate } from "swr";
import { productLevelTbodyQuery } from "../graphql-queries";
import useStores from "../hooks/useStores";
import { graphqlFetcher } from "../utils/fetchers";
import ProductLevelTable from "./product-level-table";

export default function ({ productExportCache }) {
  const [initialValues, setInitialValues] = useState();
  const [stores] = useStores();

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={async (values, formikHelpers) => {
        const results = await Promise.all(
          Object.entries(values)
            .filter(([, inputByProductId]) => !isEmpty(inputByProductId))
            .map(
              async (
                [subdomain, inputByProductId] // each store need one promise
              ) => {
                // fix the error "An operation name is required" when there are more than 1 mutation
                const operationParameters = Object.keys(inputByProductId)
                  .map(productId => gql`$${productId}: ProductInput!`)
                  .join(", ");

                const mutations = Object.keys(inputByProductId)
                    .map(
                      (productId) => gql`
                        ${productId}: productUpdate(input: $${productId}) {
                          userErrors {
                            field
                            message
                          }
                        }
                      `
                    )
                    .join("\n");
                
                const operationString = gql`
                  mutation updateProducts(${operationParameters}) {
                    ${mutations}
                  }
                `;

                await graphqlFetcher([
                  subdomain,
                  undefined,
                  operationString,
                  inputByProductId,
                ]);
                return await mutate(
                  [
                    subdomain,
                    undefined,
                    productLevelTbodyQuery,
                    {
                      query: `sku:${productExportCache.sku}`,
                    },
                  ],
                  null
                );
              }
            )
        );
        formikHelpers.resetForm();
      }}
    >
      {(formik) => (
        <>
          <Form>
            <button
              type="button"
              className="inline-flex items-center whitespace-nowrap rounded-md border border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 "
              onClick={() =>
                mutate(
                  (key) =>
                    stores.map((store) => store.subdomain).includes(key[0]),
                  null
                )
              }
            >
              <RefreshIcon className="-ml-0.5 mr-2 h-4 w-4" />
              Refresh
            </button>
            <button
              type="submit"
              className="ml-2 inline-flex items-center whitespace-nowrap rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-20"
              disabled={formik.isSubmitting}
            >
              <UploadIcon className="-ml-0.5 mr-2 h-4 w-4" />
              Upload to Shopify
            </button>
            <ProductLevelTable
              productExportCache={productExportCache}
              setInitialValues={setInitialValues}
            />
          </Form>
        </>
      )}
    </Formik>
  );
}
