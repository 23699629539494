import { createContext, useState } from "react";
import DisplaySkuData from "../components/DisplaySkuData";
import SearchSku from "../components/SearchSku";

export const MOContext = createContext();

export default function MultistoreOperations() {
  const [selectedSku, setSelectedSku] = useState({});
  const [mabangData, setMabangData] = useState({});
  const [shopifyData, setShopifyData] = useState({});
  const [roiData, setRoiData] = useState({});
  const [exchangeRates, setExchangeRates] = useState({});

  console.info("mabangData", mabangData);
  console.info("shopifyData", shopifyData);
  console.info("roiData", roiData);
  console.info("exchangeRates", exchangeRates);

  return (
    <MOContext.Provider
      value={{
        selectedSku,
        setSelectedSku,
        mabangData,
        setMabangData,
        shopifyData,
        setShopifyData,
        roiData,
        setRoiData,
        exchangeRates,
        setExchangeRates,
      }}
    >
      <div className="container mx-auto max-w-7xl">
        <SearchSku />
        {selectedSku.sku && <DisplaySkuData />}
      </div>
    </MOContext.Provider>
  );
}
