import { useFormikContext } from "formik";
import { gql } from "graphql-request";
import { useEffect } from "react";
import { productLevelTbodyQuery } from "../graphql-queries";
import useGraphqlQuery from "../hooks/useGraphqlQuery";
import Loading from "./Loading";
import ProductLevelTr from "./product-level-tr";

export default function ({
  store,
  productExportCache,
  colSpan,
  setInitialValues,
}) {
  const variables = {
    query: `sku:${productExportCache.sku}`,
  };

  const { data, isLoading } = useGraphqlQuery(
    store.subdomain,
    undefined,
    productLevelTbodyQuery,
    variables
  );

  useEffect(() => {
    if (data) {
      setInitialValues((initialValues) => ({
        ...initialValues,
        [store.subdomain]: data.products.nodes.reduce(
          (inputByProductId, { id, title, status }) => {
            inputByProductId[id.replace(/[^\w\d]/g, "")] = {
              id,
              title,
              status,
            };
            return inputByProductId;
          },
          {}
        ),
      }));
    }
  }, [data]);

  return (
    <tbody className="divide-y divide-gray-200 bg-white">
      <tr className="border-t border-gray-200">
        <th
          colSpan={colSpan}
          scope="colgroup"
          className="bg-gray-100 px-3 py-1 text-left text-xs font-semibold text-indigo-900"
        >
          {store.subdomain}
        </th>
      </tr>
      {isLoading ? (
        <Loading />
      ) : (
        data?.products?.nodes?.map((product) => (
          <ProductLevelTr product={product} store={store} key={product.id} />
        ))
      )}
    </tbody>
  );
}
