import { useState, useEffect, useRef } from "react";
import { Combobox } from "@headlessui/react";
import axios from "axios";
import Loading from "../Loading";
import useDebounce from "../../hooks/useDebounce";
import StoreSelector from "../StoreSelector";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SearchShopify({
  selectedProductId,
  setSelectedProductId,
  stores,
  selectedStore,
  setSelectedStore,
}) {
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 300);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const comboboxButton = useRef();

  useEffect(() => {
    const fetchData = async () => {
      setError(null);
      setIsLoading(true);
      await axios
        .post(
          `/api/graphql/${selectedStore.subdomain}/${process.env.REACT_APP_SHOPIFY_API_VERSION}`,
          {
            query: `query {
              shop {
                name
                currencyCode
              }
              products(first: 10, query: "title:*${debouncedQuery}* OR sku:*${debouncedQuery}*") {
                edges {
                  node {
                    id
                    title
                    status
                    handle
                    featuredImage {
                      url(transform: { maxWidth: 140 })
                    }
                    variants(first: 30) {
                      edges {
                        node {
                          id
                          sku
                        }
                      }
                    }
                  }
                }
              }
            }`,
          }
        )
        .then(function (response) {
          if (response.data?.errors) {
            setError(
              response.data?.errors.map((error) => error.message).join(", ")
            );
          } else {
            setProducts(response.data.data.products.edges);
          }
        })
        .catch(function (error) {
          setError(error);
        })
        .then(function () {
          setIsLoading(false);
        });
    };

    debouncedQuery ? fetchData() : setProducts([]);
  }, [debouncedQuery, selectedStore.subdomain]);

  useEffect(() => {
    comboboxButton.current.click();
  }, [selectedStore]);

  return (
    <Combobox
      value={selectedProductId}
      onChange={(product) => {
        if (product) {
          setSelectedProductId(product.node.id);
        }
      }}
    >
      <div className="relative">
        <Combobox.Input
          onChange={(event) => {
            setQuery(event.target.value);
          }}
          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-40 text-gray-900 shadow-sm placeholder:normal-case placeholder:text-gray-500 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 disabled:opacity-20 sm:text-sm"
          placeholder="Find product by Name or SKU..."
          displayValue={() => debouncedQuery}
          autoComplete="off"
        />

        <StoreSelector
          stores={stores}
          selectedStore={selectedStore}
          setSelectedStore={setSelectedStore}
        />

        {isLoading && (
          <div className="absolute top-1/2 right-[8.5rem] -translate-y-1/2">
            <Loading />
          </div>
        )}
        <Combobox.Button
          className="sr-only"
          ref={comboboxButton}
        ></Combobox.Button>
        {debouncedQuery && !isLoading && !error && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-80 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {products.length === 0 ? (
              <div className="relative cursor-default select-none py-2 px-3 text-gray-900">
                Nothing found.
              </div>
            ) : (
              products.map((product) => (
                <Combobox.Option
                  key={product.node.id}
                  value={product}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-pointer select-none p-1 text-gray-900",
                      active ? "bg-indigo-50" : ""
                    )
                  }
                >
                  <div className="flex items-start gap-x-2">
                    <img
                      src={
                        product.node?.featuredImage?.url
                          ? product.node?.featuredImage?.url
                          : "/No_Image_Available.jpg"
                      }
                      alt="from Mabang"
                      className="max-h-[4.5rem] max-w-[4.5rem] rounded-md"
                    />
                    <div>
                      <div className="text-xs italic text-gray-500">
                        {product.node.handle}
                      </div>
                      <div>{product.node.title}</div>
                      <div className="text-xs font-medium text-indigo-900">
                        SKU(s):{" "}
                        {product.node.variants.edges
                          .map((variant) => variant.node.sku)
                          .join(", ")}
                      </div>
                      <div
                        className={`text-xs ${
                          product.node.status === "ACTIVE"
                            ? "text-green-600"
                            : "text-red-600"
                        }`}
                      >
                        {product.node.status}
                      </div>
                    </div>
                  </div>
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
