import { Listbox, Transition } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/solid";
import { Fragment } from "react";

export default function StoreSelector({
  stores,
  selectedStore,
  setSelectedStore,
}) {
  return (
    <Listbox
      value={selectedStore}
      onChange={setSelectedStore}
      as="div"
      className="absolute inset-y-[1px] right-[1px] z-10 w-full max-w-[8rem]"
    >
      <Listbox.Button className="relative w-full cursor-default rounded-md bg-indigo-50 py-2 pl-3 pr-9 text-left focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 disabled:opacity-20 sm:text-sm">
        <span className="block truncate text-indigo-600">
          {selectedStore.alias}
        </span>
        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <SelectorIcon
            className="h-5 w-5 text-indigo-600"
            aria-hidden="true"
          />
        </span>
      </Listbox.Button>
      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {stores
            //.filter((store) =>["next-deal-shop","next-deal-shop-ca","next-deal-shop-au",].indexOf(store.subdomain) !== -1)
            .map((store) => (
              <Listbox.Option
                key={store.id}
                className={({ active }) =>
                  `relative cursor-default select-none p-2 ${
                    active ? "bg-indigo-100 text-indigo-600" : "text-gray-600"
                  }`
                }
                value={store}
              >
                <span className="block truncate">{store.alias}</span>
              </Listbox.Option>
            ))}
        </Listbox.Options>
      </Transition>
    </Listbox>
  );
}
