import axios from "axios";
import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { statefulAxiosInstance } from "../utils/fetchers";
import Loading from "../components/Loading";

export default function CreateOrUpdateShopifyCollection() {
  const [message, setMessage] = useState('');
  const [action, setAction] = useState('');
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, formState: { errors } } = useForm()

  const onSubmit = async (data) => {
    setMessage('');
    setLoading(true);

    const formData = new FormData();
    formData.append("file", data.file[0]);
    formData.append("title", data.title);
    formData.append("handle", data.handle);
    formData.append("action", action);

    try {
      await statefulAxiosInstance.post(
        `/api/shopify-collection/${action}`,
        formData
      );
      setMessage('Success');
    } catch (e) {
      setMessage(e.response?.data?.message || e.message);
    }

    setLoading(false);
  }

  return (
    <div className="container mx-auto max-w-7xl">
      <form onSubmit={handleSubmit(onSubmit)}>
        <label
          style={{
            display: 'block'
          }}
        >
          Collection Title (Required when create):
          <input
            className="ml-2 mb-2"
            style={{ width: '40vw' }}
            type="text"
            {...register("title", { required: action === 'create' })}
          />
        </label>

        <label
          style={{
            display: 'block'
          }}
        >
          Collection Handle (Required when update):
          <input
            className="ml-2 mb-2"
            style={{ width: '40vw' }}
            type="text"
            {...register("handle", { required: action === 'update' })}
          />
        </label>

        <label>
          Batch Upload:
          <input
            className="ml-2"
            type="file"
            {...register("file")}
          />
        </label>

        {
          loading ?
            <Loading className="" />
            :
            ['create', 'update'].map(action => (
              <button
                key={action}
                type="submit"
                onClick={() => setAction(action)}
                className="ml-2 inline-flex items-center whitespace-nowrap rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-20"
              >
                {action}
              </button>
            ))
        }
      </form>

      <div style={{ color: message === 'Success' ? 'green' : 'red' }}>{message}</div>
    </div>
  );
}
