import { useEffect, useRef, useState } from "react";
import { Tab } from "@headlessui/react";
import classNames from "classnames";
import MultiStoreOperationProComboBox from "../components/multi-store-operation-pro-combo-box";
import { SWRConfig } from "swr";
import ProductLevelTabPanel from "../components/product-level-tab-panel";
import SkuLevelTabPanel from "../components/sku-level-tab-panel";
import stockLevelTabPanel from "../components/stock-level-tab-panel";
import tagsManagement from "../components/tags-management";

const tabs = [
  {
    title: "SKU Level",
    component: SkuLevelTabPanel,
  },
  {
    title: "Product Level",
    component: ProductLevelTabPanel,
  },
  {
    title: "Stock Level",
    component: stockLevelTabPanel,
  },
  {
    title: "Tags Management",
    component: tagsManagement,
  },
];

export default function MultistoreOperationsPro() {
  const [selectedProductExportCache, setSelectedProductExportCache] =
    useState();
  const tableContainerRef = useRef(null);
  const [tableContainerHeight, setTableContainerHeight] = useState(null);
  useEffect(() => {
    if (tableContainerRef.current && !tableContainerHeight) {
      setTableContainerHeight(
        `calc(100vh - ${tableContainerRef.current.offsetTop}px - 32px)`
      );
    }
  });

  // make each store a form because the edit can be merged into one mutation
  // so each store load their graphql query
  // passing sku and store object is enough

  return (
    <>
      <MultiStoreOperationProComboBox
        selectedProductExportCache={selectedProductExportCache}
        onSelect={(productExportCache) => {
          setSelectedProductExportCache(productExportCache);
        }}
      />
      <SWRConfig
        value={{
          revalidateIfStale: false,
          revalidateOnFocus: false,
          revalidateOnReconnect: false,
          shouldRetryOnError: false,
        }}
      >
        <div className="mb-4 flex justify-end space-x-4">
          <div
            className="flex justify-end space-x-4"
            id="buttons-container"
          ></div>
        </div>
        <Tab.Group>
          <Tab.List className="flex space-x-4 border-b border-gray-200">
            {tabs.map(({ title }) => (
              <Tab
                key={title}
                className={({ selected }) =>
                  classNames(
                    "whitespace-nowrap rounded-t-lg border-b-2 py-3 px-4 text-sm font-medium focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500",
                    selected
                      ? "border-indigo-500 bg-indigo-50 text-indigo-600"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  )
                }
              >
                {title}
              </Tab>
            ))}
          </Tab.List>
          <div className="mt-4 flex flex-col">
            <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-4">
              <div
                className="inline-block w-full overflow-x-auto align-middle md:px-6 lg:px-4"
                ref={tableContainerRef}
                style={
                  tableContainerHeight ? { height: tableContainerHeight } : {}
                }
              >
                {/* border */}
                <div className="shadow ring-1 ring-black ring-opacity-5">
                  <Tab.Panels>
                    {tabs.map(({ component: Component, title: key }) => (
                      <Component
                        key={key}
                        productExportCache={selectedProductExportCache}
                      />
                    ))}
                  </Tab.Panels>
                </div>
              </div>
            </div>
          </div>
        </Tab.Group>
      </SWRConfig>
    </>
  );
}
