import { SearchIcon } from "@heroicons/react/outline";
import { Field, useFormikContext } from "formik";
import { gql } from "graphql-request";
import { useContext } from "react";
import Fancybox from "../hooks/Fancybox";
import useGraphqlQuery from "../hooks/useGraphqlQuery";
import { calculateDiscount } from "../utils/helpers";
import { ReportDateRangeContext } from "./product-level-tab-panel";

export default function ({ product, store }) {
  const query = gql`
    query ($query: String!) {
      shopifyqlQuery(query: $query) {
        __typename
        ... on TableResponse {
          tableData {
            unformattedData
            rowData
            columns {
              name
              dataType
              displayName
            }
          }
        }
        parseErrors {
          code
          message
          range {
            start {
              line
              character
            }
            end {
              line
              character
            }
          }
        }
      }
    }
  `;
  const numericProductId = product.id.match(/\d+/)[0];

  const dates = useContext(ReportDateRangeContext);

  const apiVersion = "2024-04";

  const variables = {
    query: `FROM products SHOW SUM(view_sessions), view_to_cart_rate, view_cart_to_checkout_rate, view_cart_checkout_to_purchase_rate, view_to_purchase_rate WHERE product_id = ${numericProductId} SINCE ${dates.from.toISODate()} UNTIL ${dates.to.toISODate()}`,
  };

  const { data, isLoading, isError } = useGraphqlQuery(
    store.subdomain,
    apiVersion,
    query,
    variables
  );

  const { data: productStatuses } = useGraphqlQuery(
    store.subdomain,
    apiVersion,
    gql`
      query {
        __type(name: "ProductStatus") {
          enumValues {
            name
          }
        }
      }
    `
  );

  const rowData =
    data?.shopifyqlQuery?.tableData?.rowData?.[0] ?? Array(5).fill(null);

  const metafields = product.metafields.nodes;

  const customLabels = [...Array(5).keys()]
    .map((index) => `custom_label_${index}`)
    .map(
      (key) =>
        metafields.find((metafield) => metafield.key === key) || {
          key,
          value: null,
        }
    );

  const defaultVariant = product.variants.nodes[0];

  return (
    <tr>
      <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
        <div className="flex items-center space-x-3 pr-8">
          <Fancybox options={{ infinite: false }}>
            <button
              //   data-fancybox={product.id}
              data-src={product?.featuredImage?.url}
              className="group relative h-full max-h-[2rem] w-full max-w-[2rem] shrink-0 overflow-hidden rounded-full"
            >
              <img
                src={product.featuredImage?.url}
                alt="from Mabang"
                className="h-full max-h-[2rem] w-full max-w-[2rem] rounded-full object-cover object-center transition duration-300 ease-in-out group-hover:scale-125"
              />
              <div className="absolute inset-0 bg-indigo-900 opacity-25 transition duration-300 ease-in-out group-hover:opacity-0"></div>
              <SearchIcon className="absolute inset-1/2 h-4 w-4 -translate-x-1/2 -translate-y-1/2 stroke-gray-100 text-indigo-600 transition duration-300 ease-in-out group-hover:opacity-0" />
            </button>
          </Fancybox>
          <div className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
            <Field
              type="text"
              name={`${store.subdomain}.${product.id.replace(
                /[^\w\d]/g,
                ""
              )}.title`}
            />
          </div>
        </div>
      </td>
      <td>
        <div className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
          <Field
            as="select"
            name={`${store.subdomain}.${product.id.replace(
              /[^\w\d]/g,
              ""
            )}.status`}
          >
            {productStatuses?.__type?.enumValues?.map(({ name }) => (
              <option value={name} key={name}>
                {name}
              </option>
            ))}
          </Field>
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
        {product.handle}
      </td>
      {rowData?.map((value) => (
        <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
          {value}
        </td>
      ))}
      <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
        {defaultVariant.sku}
      </td>
      <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
        {defaultVariant.price}
      </td>
      <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
        {defaultVariant.compareAtPrice}
      </td>
      <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
        {(
          calculateDiscount(
            defaultVariant.price,
            defaultVariant.compareAtPrice
          ) / 100
        )?.toLocaleString(undefined, {
          style: "percent",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </td>
      {customLabels?.map(({ key, value }) => (
        <td
          key={key}
          className="whitespace-nowrap px-3 py-1 text-sm text-gray-500"
        >
          {value}
        </td>
      ))}
      <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
        {product.tags.join(", ")}
      </td>
    </tr>
  );
}
