import DatePicker from "react-datepicker";

const OrderShippingCost = ({ formState, setFormState }) => {

  return (
    <form>
      <label htmlFor="created-at-begin">Begin At:</label>
      <DatePicker
        id="created-at-begin"
        selectsStart
        onChange={(date) =>
          setFormState({
            ...formState,
            params: {
              ...formState.params,
              createdAtBegin: date,
            }
          })
        }
        selected={formState.params?.createdAtBegin}
        startDate={formState.params?.createdAtBegin}
        endDate={formState.params?.createdAtEnd}
      />
      <label htmlFor="created-at-end">End At:</label>
      <DatePicker
        id="created-at-end"
        selectsEnd
        selected={formState.params?.createdAtEnd}
        startDate={formState.params?.createdAtBegin}
        endDate={formState.params?.createdAtEnd}
        onChange={(date) =>
          setFormState({
            ...formState,
            params: {
              ...formState.params,
              createdAtEnd: date,
            }
          })
        }
      />
    </form>
  );
};

export default OrderShippingCost;
