import axios from "axios";
import { useState } from "react";
import useSaveHistory from "./useSaveHistory";

export default function useShopifyMutation() {
  const [isSaveHistoryLoading, saveHistoryError, saveHistory] =
    useSaveHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);

  const handleSaveHistory = (subdomain, data) => {
    for (const product in data) {
      for (const variant in data[product].product.variants) {
        data[product].product.variants[variant].forEach((variant) => {
          let dataObject = {};
          dataObject = { store: subdomain };
          dataObject.price = variant.node.price;
          dataObject.compareAtPrice = variant.node.compareAtPrice;
          saveHistory(variant.node.sku, dataObject);
        });
      }
    }
  };

  const sendMutation = async (subdomain, products) => {
    setError(null);
    setResponse(null);
    setIsLoading(true);
    await axios
      .post(
        `/api/graphql/${subdomain}/${process.env.REACT_APP_SHOPIFY_API_VERSION}`,
        {
          query: `mutation {
            ${products.map(
              (
                { id, title, existingMetafields, newMetafields, variants },
                index
              ) => `
              Product_${index}: productUpdate(
                input: {
                  id: "${id}"
                  title: "${title}"
                  metafields: [
                    ${existingMetafields.map(
                      ({ id, value }) => `{ id: "${id}", value: "${value}" }`
                    )}
                    ${newMetafields.map(
                      ({ key, value }) =>
                        `{ key: "${key}", value: "${value}", namespace: "google", type: "string", description: "Used for Google Shopping Campaigns" }`
                    )}
                  ]
                  variants: [
                    ${variants.map(
                      ({ id, price, compareAtPrice }) =>
                        `{ id: "${id}", price: "${price}", compareAtPrice: "${compareAtPrice}" }`
                    )}
                  ]
                }
              ) {
                product {
                  id
                  variants(first: 30) {
                    edges {
                      node {
                        sku
                        price
                        compareAtPrice
                      }
                    }
                  }
                }
                userErrors {
                  field
                  message
                }
              }
            `
            )}
          }`,
        }
      )
      .then(function (response) {
        if (response.data?.errors) {
          setError(
            response.data?.errors.map((error) => error.message).join(", ")
          );
        } else {
          setResponse(response.data);
          handleSaveHistory(subdomain, response.data.data);
        }
      })
      .catch(function (error) {
        setError(error.message);
      })
      .finally(function () {
        setIsLoading(false);
      });
  };

  return [
    isLoading,
    error,
    setError,
    response,
    setResponse,
    sendMutation,
    isSaveHistoryLoading,
    saveHistoryError,
  ];
}
