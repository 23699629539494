import axios from "axios";
import { useState, useEffect, useContext } from "react";
import { MOContext } from "../pages/multistore-operations";

export default function useMabangData(selectedSku, weightBuffer) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { mabangData, setMabangData } = useContext(MOContext);

  useEffect(() => {
    const fetchData = async () => {
      console.info(`start fetching ${selectedSku}`);
      setError(null);
      setIsLoading(true);
      setMabangData((previousMabangData) => ({
        ...previousMabangData,
        [selectedSku]: {},
      }));
      await axios
        .get(`/api/stocks/${selectedSku}`, {
          params: {
            qcedWarehouseIds:
              process.env.REACT_APP_NDS_WAREHOUSE_IDS.split(","),
          },
        })
        .then(function (response) {
          console.info(`done fetching ${selectedSku}`);
          setMabangData((previousMabangData) => ({
            ...previousMabangData,
            [selectedSku]: {
              ...response.data,
              weight: parseFloat(response.data.weight) + weightBuffer,
              weightBuffer: weightBuffer,
            },
          }));
        })
        .catch(function (error) {
          setError(error);
        })
        .then(function () {
          setIsLoading(false);
        });
    };
    if (selectedSku && !(selectedSku in mabangData)) fetchData();
  }, [mabangData, selectedSku, setMabangData, weightBuffer]);

  return [isLoading, error];
}
