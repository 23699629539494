import { ExternalLinkIcon } from "@heroicons/react/outline";
import { useContext } from "react";
import useMabangData from "../hooks/useMabangData";
import { MOContext } from "../pages/multistore-operations";
import DisplayMabangData from "./DisplayMabangData";
import InputGroup from "./InputGroup";
import Loading from "./Loading";
import useShopifyReport from "../hooks/useShopifyReport";
import History from "./History";
import MarginRoi from "./MarginRoi";
import MutateInventoryPolicy from "./MutateInventoryPolicy";

export default function DisplayVariantData({
  product,
  variant,
  currencyCode,
  store,
}) {
  const weightBuffer = 30;
  const [isLoading, error] = useMabangData(variant.node.sku, weightBuffer);
  const { selectedSku, setSelectedSku, mabangData, roiData, setRoiData } =
    useContext(MOContext);
  const [isReportLoading, reportError, report, fetchReport] = useShopifyReport(
    store.subdomain,
    variant.node.sku
  );

  return (
    <div className="space-y-2 rounded-md border border-gray-200 bg-gray-50 p-2">
      <div className="flex justify-between">
        <div>
          <div className="space-x-1">
            <div className="text-gray-500 sm:inline-block">
              {variant.node?.sku === selectedSku.sku ? "selected " : null}
              SKU:
            </div>
            {variant.node?.sku === selectedSku.sku ? (
              <span className="text-sm font-semibold">{variant.node?.sku}</span>
            ) : (
              <button
                type="button"
                className="font-semibold text-indigo-600 underline hover:text-indigo-500 hover:no-underline"
                onClick={() => setSelectedSku({ sku: variant.node?.sku })}
                title="search for this SKU"
              >
                {variant.node?.sku}
              </button>
            )}
          </div>
          <div className="sm:space-x-1">
            <div className="text-gray-500 sm:inline-block">variant title:</div>
            <div className="text-gray-900 sm:inline-block">
              <span className="flex items-center">
                {product.node?.hasOnlyDefaultVariant
                  ? "same as product"
                  : variant.node?.title}
                {product.node?.onlineStoreUrl && (
                  <a
                    href={`${product.node?.onlineStoreUrl}?variant=${
                      variant.node?.id.split("/ProductVariant/")[1]
                    }`}
                    target="_blank"
                    rel="noreferrer"
                    className="inline-block font-semibold text-indigo-600 underline hover:text-indigo-500 hover:no-underline"
                    title="open this product (variant) on a new tab"
                  >
                    <ExternalLinkIcon className="ml-1 h-4 w-4" />
                  </a>
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="text-right">
          <div className="text-gray-500">
            net qty sold last 7 / 30 / 45 days:
          </div>
          <div className="text-gray-900">
            {isReportLoading ? (
              <div className="flex items-center justify-end gap-x-1">
                <Loading />
                <p className="text-indigo-600">reports are loading...</p>
              </div>
            ) : reportError ? (
              <div className="space-y-1">
                <p className="text-xs font-semibold text-red-500">
                  {reportError}
                </p>
                <button
                  onClick={() => fetchReport()}
                  type="button"
                  className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2 py-1 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-20"
                  {...(isReportLoading && { disabled: true })}
                >
                  refetch report
                </button>
              </div>
            ) : report ? (
              <div>
                {Object.values(report)
                  .map((entry) => entry.tableData.rowData[0][0])
                  .join(" / ")}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-x-2 rounded-lg border border-gray-200 bg-white p-2 pb-1">
        <InputGroup
          label={`price`}
          name={`${product.node?.id}_-_${variant.node?.id}_-_price`}
          leadingtext={currencyCode}
          value={
            roiData[
              `${store.subdomain}_-_${product.node?.id}_-_${variant.node?.id}_-_price`
            ]
          }
          onChange={(e) =>
            setRoiData((roiData) => ({
              ...roiData,
              [`${store.subdomain}_-_${product.node?.id}_-_${variant.node?.id}_-_price`]:
                e.target.value,
            }))
          }
        />
        <InputGroup
          label="compare at price"
          name={`${product.node?.id}_-_${variant.node?.id}_-_compareAtPrice`}
          defaultValue={variant.node?.compareAtPrice}
          leadingtext={currencyCode}
        />

        <History
          sku={variant.node.sku}
          storeAlias={store.alias}
          storeSubdomain={store.subdomain}
          productTitle={product.node?.title}
        />
      </div>
      <div className="sm:flex sm:items-center sm:gap-x-1">
        <div className="text-gray-500 sm:inline-block">Inventory Policy:</div>
        <div className="mt-0.5">
          <MutateInventoryPolicy
            initialInventoryPolicy={variant.node?.inventoryPolicy}
            storeSubdomain={store.subdomain}
            variantId={variant.node.id}
          />
        </div>
      </div>
      <div className="py-1 text-sm font-medium text-indigo-900">
        Data from Mabang
      </div>
      {isLoading ? (
        <div className="flex items-center gap-x-1">
          <Loading />
          <span className="ml-2 text-sm text-indigo-600 sm:ml-3">
            Retriving {variant.node.sku} data from Mabang ...
          </span>
        </div>
      ) : error ? (
        <p className="text-sm font-semibold text-red-500">
          Error: ${error.message}
        </p>
      ) : (
        mabangData[variant.node?.sku] &&
        mabangData[variant.node?.sku].sku && (
          <>
            <DisplayMabangData data={mabangData[variant.node?.sku]} />
            <MarginRoi
              store={store}
              product={product}
              variant={variant}
              currencyCode={currencyCode}
            />
          </>
        )
      )}
    </div>
  );
}
