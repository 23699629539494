import { gql } from "graphql-request";

export const productLevelTbodyQuery = gql`
  query ($query: String!) {
    products(query: $query, first: 10) {
      nodes {
        id
        title
        handle
        featuredImage {
          url
        }
        variants(first: 1) {
          nodes {
            availableForSale
            sku
            price
            compareAtPrice
          }
        }
        metafields(namespace: "google", first: 20, reverse: true) {
          nodes {
            key
            value
          }
        }
        tags
        status
      }
    }
  }
`;

export const skuReviewFormQuery = gql`
  query ($query: String!) {
    products(query: $query, first: 25) {
      nodes {
        title
        handle
        featuredImage {
          url
        }
        variants(first: 25) {
          nodes {
            id
            sku
            title
            price
            compareAtPrice
          }
        }
      }
    }
  }
`;
