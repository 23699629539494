import useSWR from "swr";
import { statefulRestfulFetcher } from "../utils/fetchers";

export default function useLatestPurchaseOrderCache(sku) {
  const { data, error } = useSWR(
    sku &&
      `/api/stock-export-caches/${sku}/purchase-order-export-caches/latest`,
    statefulRestfulFetcher
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
}
