import axios from "axios";
import { useState } from "react";

export default function useSaveSettings() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const saveSettings = async (formData) => {
    const formDataObj = {};
    formData.forEach((value, key) => (formDataObj[key] = value));
    setError(null);
    setIsLoading(true);

    await axios
      .post("/api/settings", { data: [JSON.stringify(formDataObj)] })
      .catch(function (error) {
        setError(error.response);
      })
      .finally(function () {
        setIsLoading(false);
      });
  };

  return [isLoading, error, saveSettings];
}
