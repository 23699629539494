import { useState } from "react";
import { Switch } from "@headlessui/react";
import Loading from "./Loading";
import useMutateInventoryPolicy from "../hooks/useShopifyMutateInventoryPolicy";

export default function MutateInventoryPolicy({
  initialInventoryPolicy,
  storeSubdomain,
  variantId,
}) {
  const [inventoryPolicy, setInventoryPolicy] = useState(
    initialInventoryPolicy === "CONTINUE" ? true : false
  );
  const [isLoading, error, mutateInventoryPolicy] = useMutateInventoryPolicy();

  const handleSwitch = () => {
    mutateInventoryPolicy(
      storeSubdomain,
      variantId,
      !inventoryPolicy,
      setInventoryPolicy
    );
  };

  return (
    <div className="flex items-center gap-x-1">
      <div className="flex items-center justify-center gap-x-1">
        <span className="text-[10px] font-medium">CONTINUE</span>
        <Switch
          checked={inventoryPolicy}
          onChange={() => handleSwitch()}
          className={`${inventoryPolicy ? "bg-green-600" : "bg-red-600"}
          relative inline-flex h-[21px] w-[39px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 disabled:opacity-20`}
          {...(isLoading && { disabled: true })}
        >
          <span className="sr-only">Inventory Policy</span>
          <span
            aria-hidden="true"
            className={`${
              inventoryPolicy ? "translate-x-0" : "translate-x-[18px]"
            }
            pointer-events-none inline-block h-[17px] w-[17px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
        <span className="text-[10px] font-medium">DENY</span>
      </div>
      {isLoading && (
        <div className="flex items-center gap-x-1">
          <Loading />
          <span className="inline-block text-xs text-indigo-600">
            Changing Inventory Policy
          </span>
        </div>
      )}
      {error && (
        <p className="text-xs font-semibold text-red-500">Error: {error}</p>
      )}
    </div>
  );
}
