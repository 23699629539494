import useSWR from "swr";
import { statefulRestfulFetcher } from "../utils/fetchers";

export default function usePurchasePriceFallback(sku) {
  const { data, error } = useSWR(
    sku && `/api/stock-export-caches/${sku}/purchase-price-fallback`,
    statefulRestfulFetcher
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
}
