import { useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../components/auth";

const Login = () => {
  let history = useHistory();
  let location = useLocation();
  let auth = useAuth();

  const [input, setInput] = useState({
    name: "",
    email: "",
    password: "",
    "password-confirmation": "",
  });

  const handleInputChange = (e) =>
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value,
    });

  const { from } = location.state || { from: { pathname: "/" } };

  const register = async () => {
    await auth.register(input);
    history.replace(from); //will cause a refresh/redirect like effect.
  };

  return auth.user ? (
    <Redirect to={from} />
  ) : (
    <>
      <form>
        <label htmlFor="name">Name:</label>
        <input
          id="name"
          type="text"
          name="name"
          value={input.name}
          onChange={handleInputChange}
        />
        <label htmlFor="email">Email:</label>
        <input
          id="email"
          type="email"
          name="email"
          autoComplete="username"
          value={input.email}
          onChange={handleInputChange}
        />
        <label htmlFor="password">Password:</label>
        <input
          id="password"
          type="password"
          name="password"
          autoComplete="new-password"
          value={input.password}
          onChange={handleInputChange}
        />
        <label htmlFor="password-confirmation">Confirm Password:</label>
        <input
          id="password-confirmation"
          type="password"
          name="password-confirmation"
          autoComplete="new-password"
          value={input["password-confirmation"]}
          onChange={handleInputChange}
        />
        <button
          onClick={(e) => {
            e.preventDefault();
            register();
          }}
        >
          Register
        </button>
      </form>
    </>
  );
};

export default Login;
