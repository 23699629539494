import { Tab } from "@headlessui/react";
import { RefreshIcon, UploadIcon } from "@heroicons/react/outline";
import { Formik } from "formik";
import { gql } from "graphql-request";
import { mutate } from "swr";
import { skuReviewFormQuery } from "../graphql-queries";
import useStores from "../hooks/useStores";
import { graphqlFetcher } from "../utils/fetchers";
import SkuReviewForm from "./sku-review-form";

const headings = [
  "Product title",
  "Variant Title",
  "SKU",
  <>
    Price
    <br />
    (Store Currency)
  </>,
  <>
    Compare at Price
    <br />
    (Store Currency)
  </>,
  "Discount %",
  <>
    Price
    <br />
    (USD)
  </>,
  "Unit Cost %",
  "Margin %",
  "ROAS",
  "状态",
  "分类信息",
  "采购单价",
  "运费单价",
  "产品总价",
  <>
    Int'l Shipping Cost
    <br />
    (CNY)
  </>,
  <>
    Unit Cost <br />
    (CNY)
  </>,
  <>
    Unit Cost <br />
    (USD)
  </>,
  "是否带电池",
  "重量",
  "体积重",
  "长",
  "宽",
  "高",
];

export default function ({ productExportCache }) {
  const [stores] = useStores();

  if (!productExportCache) {
    return <Tab.Panel />;
  }

  return (
    <Tab.Panel>
      <Formik
        initialValues={{}}
        onSubmit={(values, formikHelpers) => {
          const query = gql`
            mutation productVariantUpdate($input: ProductVariantInput!) {
              productVariantUpdate(input: $input) {
                userErrors {
                  field
                  message
                }
              }
            }
          `;

          const promises = [];

          for (const [storeId, inputsByVariantId] of Object.entries(values)) {
            for (const [variantId, inputWithNull] of Object.entries(
              inputsByVariantId
            )) {
              const input = {};
              for (const [key, value] of Object.entries(inputWithNull)) {
                if (value !== null) {
                  input[key] = value;
                }
              }
              if (Object.keys(input).length > 0) {
                const store = stores.find(
                  (store) => store.id.toString() === storeId
                );
                promises.push(
                  graphqlFetcher([
                    store.subdomain,
                    undefined,
                    query,
                    {
                      input: {
                        id: variantId,
                        ...input,
                      },
                    },
                  ]).then(() =>
                    mutate([
                      store?.subdomain,
                      undefined,
                      skuReviewFormQuery,
                      {
                        query: `sku:${productExportCache.sku}`,
                      },
                    ])
                  )
                );
              }
            }
          }
          return Promise.all(promises).then(() => formikHelpers.resetForm());
        }}
      >
        {(formik) => (
          <>
            <div className="sticky top-0 z-10 bg-white">
              <button
                type="button"
                className="inline-flex items-center whitespace-nowrap rounded-md border border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 "
                onClick={() =>
                  mutate(
                    (key) =>
                      stores.map((store) => store.subdomain).includes(key[0]),
                    null
                  )
                }
              >
                <RefreshIcon className="-ml-0.5 mr-2 h-4 w-4" />
                Refresh
              </button>
              <button
                type="button"
                className="ml-2 inline-flex items-center whitespace-nowrap rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-20"
                onClick={() => formik.submitForm()}
                disabled={formik.isSubmitting}
              >
                <UploadIcon className="-ml-0.5 mr-2 h-4 w-4" />
                Upload to Shopify
              </button>
            </div>
            <table className="min-w-full">
              <thead className="sticky top-9 z-10 bg-white">
                <tr>
                  {headings.map((value, key) => (
                    <th
                      key={key}
                      scope="col"
                      className="whitespace-nowrap bg-white px-3 py-1 text-left text-sm font-semibold text-gray-900"
                    >
                      {value}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {productExportCache &&
                  stores?.map((store) => (
                    <SkuReviewForm
                      store={store}
                      productExportCache={productExportCache}
                      key={store.subdomain}
                    />
                  ))}
              </tbody>
            </table>
          </>
        )}
      </Formik>
    </Tab.Panel>
  );
}
