import axios from "axios";
import { useState } from "react";

export default function useHistory() {
  const [history, setHistory] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchHistory = async (sku) => {
    setError(null);
    setIsLoading(true);
    await axios
      .get(`/api/stocks/${sku}/histories`)
      .then(function (response) {
        setHistory(response.data.data);
      })
      .catch(function (error) {
        setError(error);
      })
      .finally(function () {
        setIsLoading(false);
      });
  };

  return [isLoading, error, history, fetchHistory];
}
