function HtmlTag({ level, className, children }) {
  let tag = <h1 className={`text-3xl ${className}`}>{children}</h1>;
  level === 2 &&
    (tag = <h2 className={`text-2xl ${className}`}>{children}</h2>);
  level === 3 && (tag = <h3 className={`text-xl ${className}`}>{children}</h3>);
  level === 4 && (tag = <h4 className={`text-lg ${className}`}>{children}</h4>);
  level === 5 &&
    (tag = <h5 className={`text-base ${className}`}>{children}</h5>);
  level === 6 && (tag = <h6 className={`text-sm ${className}`}>{children}</h6>);
  return tag;
}

export default function Heading({
  level = 1,
  color = "text-gray-900",
  leading = "leading-10",
  className,
  children,
}) {
  return (
    <HtmlTag
      level={level}
      className={`grow break-all font-bold ${color} ${leading} ${className}`}
    >
      {children}
    </HtmlTag>
  );
}
