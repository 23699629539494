export default function InputGroup({
  label,
  name,
  type = "text",
  bg = "bg-white",
  ...props
}) {
  return (
    <div
      className={`relative rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600 ${bg}`}
    >
      <label
        htmlFor={name}
        className={`absolute -top-2 left-2 -mt-px inline-block px-1 text-xs font-medium text-gray-500 ${bg}`}
      >
        {label}
      </label>
      <div className="flex items-center">
        {props.leadingtext ? (
          <div className="pointer-events-none mr-1">
            <span className="text-gray-500 sm:text-sm">
              {props.leadingtext}
            </span>
          </div>
        ) : null}

        <input
          type={type}
          name={name}
          id={name}
          className={`block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm ${bg}`}
          {...props}
        />

        {props.trailingtext ? (
          <div className="pointer-events-none ml-1">
            <span className="text-gray-500 sm:text-sm">
              {props.trailingtext}
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
}
