import useStores from "../hooks/useStores";
import ProductLevelTbody from "./product-level-tbody";

const headings = [
  "Product Title",
  "Status",
  "Handle",
  "Sessions",
  "ATC",
  "Checkout",
  "Purchase",
  "Conversation %",
  "Default SKU",
  "SKU Price",
  "SKU Compare at Price",
  "Discount",
  ...[...Array(5).keys()].map((index) => `Custom Label ${index}`),
  "Tags",
];
export default function ({ productExportCache, setInitialValues }) {
  const [stores] = useStores();

  return (
    <table className="min-w-full">
      <thead className="sticky top-0 z-10 bg-white">
        <tr>
          {headings.map((value, key) => (
            <th
              key={key}
              scope="col"
              className="whitespace-nowrap bg-white px-3 py-1 text-left text-sm font-semibold text-gray-900"
            >
              {value}
            </th>
          ))}
        </tr>
      </thead>
      {stores?.map((store) => (
        <ProductLevelTbody
          store={store}
          productExportCache={productExportCache}
          key={store.subdomain}
          colSpan={headings.length}
          setInitialValues={setInitialValues}
        />
      ))}
    </table>
  );
}
