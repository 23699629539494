import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import useStockExportCacheTags from "../hooks/useStockExportCacheTags";
import { XIcon } from "@heroicons/react/solid";
import { Combobox, Tab } from "@headlessui/react";
import useTags from "../hooks/useTags";
import {
  statefulAxiosInstance,
  statefulRestfulFetcher,
} from "../utils/fetchers";
import classNames from "classnames";
import useSWRInfinite from "swr/infinite";

export default function ({ productExportCache }) {
  const { data, error, isLoading, isValidating, mutate, size, setSize } =
    useSWRInfinite((pageIndex, previousPageData) => {
      if (!productExportCache) return null;
      if (previousPageData?.data && !previousPageData?.data?.length)
        return null;
      return [
        productExportCache.isComboSku ?
          `/api/combo-sku/${productExportCache.sku}/tags`
          :
          `/api/stock-export-caches/${productExportCache.sku}/tags`,
        { page: pageIndex + 1 },
      ];
    }, statefulRestfulFetcher);

  const [message, setMessage] = useState();

  useEffect(() => {
    if (data) {
      if (size < data[0].meta.last_page) {
        setSize(data[0].meta.last_page);
      }
    }
  }, [size, data]);

  return (
    <Tab.Panel>
      <BatchUploadForm
        onSubmit={async (data) => {
          const formData = new FormData();
          formData.append("file", data.file[0]);
          try {
            await statefulAxiosInstance.post(
              `/api/stock-export-caches-tags/upload`,
              formData
            );
            setMessage(<div>Success</div>);
          } catch (e) {
            setMessage(<div>{e.message}</div>);
          }
        }}
      />
      {data && size === data[0].meta.last_page && (
        <TagsBox
          tags={data.flatMap(({ data }) => data)}
          onSubmit={async (data) => {
            try {
              await statefulAxiosInstance.patch(
                productExportCache?.isComboSku ?
                  `/api/combo-sku/${productExportCache?.sku}/tags`
                  :
                  `/api/stock-export-caches/${productExportCache?.sku}/tags`,
                data
              );
              setMessage(<div>Success</div>);
              mutate();
            } catch (e) {
              setMessage(<div>{e.message}</div>);
            }
          }}
        />
      )}
      {message}
    </Tab.Panel>
  );
}

function BatchUploadForm({ onSubmit }) {
  const { handleSubmit, register } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <label>
        Batch Upload
        <input type="file" {...register("file")} />
      </label>
      <button type="submit">upload</button>
    </form>
  );
}

function TagsBox({ onSubmit, tags }) {
  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      tags,
    },
  });

  const [params, setParams] = useState({});

  const { data: response } = useTags(params);

  const { append, remove } = useFieldArray({
    control,
    name: "tags",
  });

  return (
    <form
      className="space-x-2 bg-white p-2 before:content-['\200b']"
      onSubmit={handleSubmit(onSubmit)}
    >
      {watch("tags").map(({ value }, key) => (
        <span
          key={value}
          className="relative inline-block rounded bg-indigo-50 p-3"
        >
          {value}
          <button onClick={() => remove(key)}>
            {<XIcon className="absolute top-1 right-1 h-4 w-4" />}
          </button>
        </span>
      ))}
      <Combobox
        onChange={(value) => {
          append({ value });
          setParams({});
        }}
        value={null}
      >
        <div className="relative inline-block">
          <Combobox.Input
            onChange={(event) =>
              setParams({ value: event.currentTarget.value })
            }
          />
          <Combobox.Options className="absolute left-0 right-0 top-full bg-white p-2">
            {!watch("tags")
              .map(({ value }) => value)
              .includes(params?.value) && (
                <Combobox.Option
                  value={params?.value}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-default select-none py-2 pl-3 pr-9 uppercase",
                      active ? "bg-indigo-600 text-white" : "text-gray-900"
                    )
                  }
                >
                  {params?.value}
                </Combobox.Option>
              )}
            {response?.data
              ?.filter(({ value }) => value !== params?.value)
              ?.filter(
                ({ value }) =>
                  !watch("tags")
                    .map(({ value }) => value)
                    .includes(value)
              )
              ?.map(({ value }) => (
                <Combobox.Option
                  key={value}
                  value={value}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-default select-none py-2 pl-3 pr-9 uppercase",
                      active ? "bg-indigo-600 text-white" : "text-gray-900"
                    )
                  }
                >
                  {value}
                </Combobox.Option>
              ))}
          </Combobox.Options>
        </div>
      </Combobox>
      <button type="submit" className="mt-5 block rounded bg-indigo-100 p-2">
        save
      </button>
    </form>
  );
}
