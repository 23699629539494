import axios from "axios";
import { useState, useEffect, useContext } from "react";
import { MOContext } from "../pages/multistore-operations";

export default function useExchange() {
  const { setExchangeRates } = useContext(MOContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setError(null);
      setIsLoading(true);
      const axiosInstance = axios.create({
        withCredentials: false,
      });
      await axiosInstance
        .get(
          `https://api.exchangerate.host/latest?base=USD&symbols=USD,EUR,GBP,BRL,MXN,CNY,HKD,CAD&source=ecb&cache=${Date.now()}`
        )
        .then(function (response) {
          setExchangeRates((existingData) => ({
            ...existingData,
            date: response.data.date,
            USD_spread: 0,
            HKD_spread: 0,
            CAD_spread: 0,
            ...response.data.rates,
          }));
        })
        .catch(function (error) {
          setError(error);
        })
        .then(function () {
          setIsLoading(false);
        });
    };
    fetchData();
  }, [setExchangeRates]);

  return [isLoading, error];
}
