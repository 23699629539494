import useSWR from "swr";
import { statefulRestfulFetcher } from "../utils/fetchers";

export default function useMarginPercentage(sku, storeId, price) {
  const { data, error } = useSWR(
    sku &&
      storeId &&
      price && [
        `/api/stock-export-caches/${sku}/stores/${storeId}/margin-percentage`,
        {
          price,
        },
      ],
    statefulRestfulFetcher
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
}
