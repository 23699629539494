import useSWR from "swr";
import { statefulRestfulFetcher } from "../utils/fetchers";

export default function useInternationalShippingCostInCny(store, sku) {
  const { data, error } = useSWR(
    sku &&
      store && [
        `/api/stock-export-caches/${sku}/stores/${store.id}/international-shipping-cost-in-cny`,
      ],
    statefulRestfulFetcher
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
}
