import { useFormikContext } from "formik";
import { DateTime } from "luxon";
import { useEffect, useRef, useState } from "react";

export default function ({ onChange, dates }) {
  const fromRef = useRef();
  const toRef = useRef();

  useEffect(() => {
    if (fromRef.current) {
      fromRef.current.valueAsDate = dates.from.toJSDate();
    }
    if (toRef.current) {
      toRef.current.valueAsDate = dates.to.toJSDate();
    }
  }, [dates]);

  return (
    <>
      <label>
        from
        <input
          type="date"
          ref={fromRef}
          onChange={(e) => {
            onChange({
              ...dates,
              from: DateTime.fromJSDate(e.currentTarget.valueAsDate),
            });
          }}
        />
      </label>
      <label>
        to
        <input
          type="date"
          ref={toRef}
          onChange={(e) => {
            onChange({
              ...dates,
              to: DateTime.fromJSDate(e.currentTarget.valueAsDate),
            });
          }}
        />
      </label>
    </>
  );
}
