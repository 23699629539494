import useSWR from "swr";
import { statefulRestfulFetcher } from "../utils/fetchers";

export default function useProductExportCaches(sku) {
  const { data, error } = useSWR(
    sku && ["/api/stock-export-caches", { sku }],
    statefulRestfulFetcher
  );

  return {
    response: data,
    isLoading: !error && !data,
    isError: error,
  };
}
