import { RefreshIcon } from "@heroicons/react/outline";
import axios from "axios";
import { Form, Formik } from "formik";
import InputGroup from "../components/InputGroup";
import Loading from "../components/Loading";
import StoresManager from "../components/store-manager";
import useExchangeRates from "../hooks/useExchangeRates";
import useSettings from "../hooks/useSettings";
import { useEffect, useState } from "react";

export default function () {
  const base = "USD";
  const currencyCodes = ["GBP", "EUR", "BRL", "MXN", "CNY", "HKD"];
  const {
    data: settings,
    isLoading: settingsIsLoading,
    error: settingsError,
    isValidating,
    mutate,
  } = useSettings();

  // const {
  //   data: exchangeRates,
  //   isLoading: exchangeRateIsLoading,
  //   isError: exchangeRateIsError,
  // } = useExchangeRates("USD", currencyCodes);
  
  const [exchangeRates, setExchangeRates] = useState({});
  const [exchangeRateIsLoading, setExchangeRateIsLoading] = useState(false);
  const [exchangeRateIsError, setExchangeRateIsError] = useState(false);

  useEffect(() => {
    setExchangeRateIsLoading(true);
    axios.post("/api/settings/getLatestExchangeRate", {
      base,
      symbols: currencyCodes.join(","),
    })
    .then(function (response) {
      setExchangeRates(response.data);
    })
    .catch(function (e) {
      setExchangeRateIsError(e);
    }).finally(() => {
      setExchangeRateIsLoading(false);
   });
  }, []);

  return (
    <>
      <StoresManager />
      <Formik
        enableReinitialize={true}
        initialValues={
          settings ?? {
            ...currencyCodes.reduce(
              (obj, currencyCode) => ({
                ...obj,
                [`${currencyCode}_spread`]: "0.00",
              }),
              {}
            ),
            CNY_manual: "0.00",
          }
        }
        onSubmit={(values) => {
          return axios
            .post("/api/settings", {
              data: [JSON.stringify(values)],
            })
            .then(() => mutate());
        }}
      >
        {(formik) => (
          <Form className="divide-y">
            <div className="bg-gray-50 px-4 py-1 text-sm sm:flex sm:items-center sm:justify-end">
              <button
                type="submit"
                className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-indigo-900 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-20"
                disabled={formik.isSubmitting}
              >
                {formik.isSubmitting && (
                  <RefreshIcon className="-ml-0.5 mr-2 h-4 w-4 animate-spin" />
                )}
                Save data
              </button>
            </div>
            <div className="p-4 text-sm text-gray-900">
              {settingsIsLoading || exchangeRateIsLoading ? (
                <div className="flex items-center gap-x-1 px-4 py-2 sm:px-6">
                  <Loading />
                  <span className="ml-2 text-sm text-indigo-600 sm:ml-3">
                    Retriving exchange rates ...
                  </span>
                </div>
              ) : settingsError || exchangeRateIsError || formik.status ? (
                <p className="px-4 py-2 text-sm font-semibold text-red-500 sm:px-6">
                  Error: {settingsError?.message} {exchangeRateIsError?.message}{" "}
                  {formik.status}
                </p>
              ) : exchangeRates?.date ? (
                <div className="space-y-4">
                  <div className="flex items-center gap-x-1">
                    <span>USD 1</span> <span>=</span>
                    <InputGroup
                      label="Manual rate"
                      leadingtext="CNY"
                      {...formik.getFieldProps("CNY_manual")}
                    />
                    <span className="space-x-1">
                      <span className="text-gray-500">CNY</span>
                      <span>
                        {exchangeRates.rates.CNY}
                        <span className="text-red-600">*</span>
                      </span>
                    </span>
                  </div>

                  <div>
                    <div className="grid gap-y-3 sm:grid-cols-2">
                      <div className="flex items-center gap-x-1">
                        <span>
                          GBP 1 = USD {(1 / exchangeRates.rates.GBP).toFixed(4)}
                          <span className="text-red-600">*</span>
                        </span>
                        <span>+</span>
                        <div className="w-24">
                          <InputGroup
                            label="GBP spread"
                            trailingtext="%"
                            {...formik.getFieldProps("GBP_spread")}
                          />
                        </div>
                        <span>=</span>
                        <span>
                          {(
                            (1 / exchangeRates.rates.GBP) *
                            ((100 + parseFloat(formik?.values?.GBP_spread)) /
                              100)
                          ).toFixed(6)}
                        </span>
                      </div>

                      <div className="flex items-center gap-x-1">
                        <span>
                          EUR 1 = USD {(1 / exchangeRates.rates.EUR).toFixed(4)}
                          <span className="text-red-600">*</span>
                        </span>
                        <span>+</span>
                        <div className="w-24">
                          <InputGroup
                            label="EUR spread"
                            trailingtext="%"
                            {...formik.getFieldProps("EUR_spread")}
                          />
                        </div>
                        <span>=</span>
                        <span>
                          {(
                            (1 / exchangeRates.rates.EUR) *
                            ((100 + parseFloat(formik?.values?.EUR_spread)) /
                              100)
                          ).toFixed(4)}
                        </span>
                      </div>

                      <div className="flex items-center gap-x-1">
                        <span>
                          USD 1 = BRL {exchangeRates.rates.BRL}
                          <span className="text-red-600">*</span>
                        </span>
                        <span>+</span>
                        <div className="w-24">
                          <InputGroup
                            label="BRL spread"
                            trailingtext="%"
                            value={-formik.values?.BRL_spread}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "BRL_spread",
                                (-e.target.value).toString()
                              )
                            }
                            type="number"
                            step="0.01"
                          />
                        </div>
                        <span>=</span>
                        <span>
                          {(
                            parseFloat(exchangeRates.rates.BRL) *
                            ((100 - parseFloat(formik?.values?.BRL_spread)) /
                              100)
                          ).toFixed(4)}
                        </span>
                      </div>

                      <div className="flex items-center gap-x-1">
                        <span>
                          USD 1 = MXN {exchangeRates.rates.MXN}
                          <span className="text-red-600">*</span>
                        </span>
                        <span>+</span>
                        <div className="w-24">
                          <InputGroup
                            label="MXN spread"
                            trailingtext="%"
                            value={-formik.values?.MXN_spread}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "MXN_spread",
                                (-e.target.value).toString()
                              )
                            }
                            type="number"
                            step="0.01"
                          />
                        </div>
                        <span>=</span>
                        <span>
                          {(
                            parseFloat(exchangeRates.rates.MXN) *
                            ((100 - parseFloat(formik?.values?.MXN_spread)) /
                              100)
                          ).toFixed(4)}
                        </span>
                      </div>
                    </div>

                    <p className="-mb-2 mt-1 space-x-1 text-right text-xs italic text-gray-500">
                      <span>
                        <span className="text-red-600">*</span>source:
                      </span>
                      <span>
                        {exchangeRates?.date
                          ? exchangeRates?.date.split("-").reverse().join("/")
                          : null}
                      </span>
                    </p>
                  </div>
                </div>
              ) : null}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
