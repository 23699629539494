import Loading from "./Loading";
import DataFromShopify from "./DataFromShopify";
import useStores from "../hooks/useStores";
import { useContext, useEffect, useRef } from "react";
import { MOContext } from "../pages/multistore-operations";
import MOSettings from "./MOSettings";
import { Disclosure } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/solid";

export default function DisplaySkuData() {
  const { shopifyData, mabangData, exchangeRates } = useContext(MOContext);
  const [stores, isLoading, error] = useStores();
  const disclosureRef = useRef(null);

  function getLastSkuFromFirstStore() {
    /**
     * I'm looking only at the 1st store because it's usually the main one
     * and it might happen that in the middle there is a store that doesn't
     * contain any products for the selected SKU so it would be undefined.
     */
    if (shopifyData[stores[0].subdomain]?.data?.products?.edges.length === 0)
      return "not found";
    return shopifyData[stores[0].subdomain]?.data?.products?.edges[
      shopifyData[stores[0].subdomain]?.data?.products?.edges.length - 1
    ].node?.variants?.edges[
      shopifyData[stores[0].subdomain]?.data?.products?.edges[
        shopifyData[stores[0].subdomain]?.data?.products?.edges.length - 1
      ].node?.variants?.edges.length - 1
    ].node?.sku;
  }

  useEffect(() => {
    exchangeRates?.date && disclosureRef.current.click();
  }, [exchangeRates.date]);

  console.info("stores", stores);

  return (
    <div className="mt-8 space-y-4">
      <div className="-mx-4 hidden divide-y overflow-hidden border border-indigo-200/50 bg-white shadow sm:mx-0 sm:rounded-lg">
        {/** Tommy asked to remove this part since it's taking too much space
        but I CANNOT remove this one below because it's feeding some context data
        so best I can do is close after exchagerates are loaded (see the useEffect) */}
        <Disclosure defaultOpen={true}>
          {({ open }) => (
            <>
              <Disclosure.Button
                className="w-full px-4 py-1 text-sm sm:flex sm:items-center"
                ref={disclosureRef}
              >
                <p className="text-left font-medium text-indigo-900 sm:grow">
                  Exchange Rates & Spreads
                </p>
                <ChevronRightIcon
                  className={
                    open
                      ? "h-6 w-6 rotate-90 transform text-indigo-900"
                      : "h-6 w-6 text-indigo-900"
                  }
                />
              </Disclosure.Button>
              <Disclosure.Panel className="text-gray-500">
                <MOSettings />
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
      {isLoading ? (
        <div className="flex items-center gap-x-1">
          <Loading className="mx-4 my-1 sm:mx-6" />
          <span className="-ml-2 text-sm text-indigo-600 sm:-ml-3">
            Loading stores ...
          </span>
        </div>
      ) : error ? (
        <p className="px-4 py-1 text-sm font-semibold text-red-500 sm:px-6">
          Error: ${error.message}
        </p>
      ) : (
        stores?.length > 0 &&
        stores
          //.filter((store) => ["next-deal-shop"].indexOf(store.subdomain) !== -1)
          .map((store, index, stores) =>
            /**
                Unfortunately Mabang cannot be queried in parallel so here is
                the solution to serialise the requests from different SKUs
                from different stores.
                - First store data can be displayed.
                - For the following stores I need to check if the last variant
                  of the last product of the previous store has been fetched.
                - To do that I need to verify that the context mabangData variable has 
                  some data inside (i.e.: the actual SKU).
                */
            index === 0 ? (
              <DataFromShopify key={index} store={store} />
            ) : (mabangData[getLastSkuFromFirstStore()] &&
                mabangData[getLastSkuFromFirstStore()].sku) ||
              getLastSkuFromFirstStore() === "not found" ? (
              <DataFromShopify key={index} store={store} />
            ) : null
          )
      )}
    </div>
  );
}
