import axios from "axios";
import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { statefulAxiosInstance } from "../utils/fetchers";
import Loading from "../components/Loading";

export default function BulkUpdateSkuPrice() {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, formState: { errors } } = useForm()

  const onSubmit = async (data) => {
    if(data.file[0]) {
      setMessage('');
      setLoading(true);
  
      const formData = new FormData();
      formData.append("file", data.file[0]);
  
      try {
        await statefulAxiosInstance.post(
          `/api/shopify-product/update-sku-price`,
          formData
        );
        setMessage('Success');
      } catch (e) {
        setMessage(e.response?.data?.message || e.message);
      }
  
      setLoading(false);
    } else {
      setMessage('Please choose file');
    }
  }

  return (
    <div className="container mx-auto max-w-7xl">
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>
          Batch Upload:
          <input
            className="ml-2"
            type="file"
            {...register("file")}
          />
        </label>

        {
          loading ?
            <Loading className="" />
            :
            <button
              type="submit"
              className="ml-2 inline-flex items-center whitespace-nowrap rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-20"
            >
              update
            </button>
        }
      </form>

      <div style={{ color: message === 'Success' ? 'green' : 'red' }}>{message}</div>
    </div>
  );
}
