import useSWR from "swr";
import { statefulRestfulFetcher } from "../utils/fetchers";

export default function usePriceInUsd(store, price) {
  const { data, error } = useSWR(
    price &&
      store && [
        `/api/stores/${store.id}/price-in-usd`,
        {
          price,
        },
      ],
    statefulRestfulFetcher
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
}
