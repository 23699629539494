import { RefreshIcon } from "@heroicons/react/outline";
import { useState } from "react";
import Alert from "../components/Alert";
import Loading from "../components/Loading";
import useEditStore from "../hooks/useEditStore";
import useStores from "../hooks/useStores";

export default function StoresManager() {
  const [stores, isLoading, error] = useStores();
  const [isEditLoading, editError, setEditError, editStore] = useEditStore();
  const [currentEditedStore, setCurrentEditedStore] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    const submittedData = new FormData(event.currentTarget);
    setCurrentEditedStore(submittedData.get("id"));
    editStore(submittedData.get("id"), submittedData);
  };

  return (
    <div className="container mx-auto max-w-7xl">
      {editError && (
        <Alert
          type="error"
          dismissable={true}
          onClick={() => setEditError(null)}
        >
          {editError?.statusText} {editError?.data?.message}{" "}
          {editError?.data?.errors &&
            Object.entries(editError?.data?.errors).map(
              (error) => `${error[1][0]} `
            )}
        </Alert>
      )}

      {isLoading ? (
        <div className="flex items-center gap-x-1">
          <Loading className="mx-4 my-1 sm:mx-6" />
          <span className="-ml-2 text-sm text-indigo-600 sm:-ml-3">
            Loading stores ...
          </span>
        </div>
      ) : error ? (
        <p className="px-4 py-1 text-sm font-semibold text-red-500 sm:px-6">
          Error: ${error.message}
        </p>
      ) : (
        stores?.length > 0 && (
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Alias
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Subdomain
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900"
                      >
                        Shipping rate <br /> with battery
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900"
                      >
                        Shipping rate <br /> without battery
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900"
                      >
                        Transaction costs <br /> percentage
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {stores.map((store) => (
                      <tr key={store.id}>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <form
                            id={`${store.subdomain}_-_form`}
                            onSubmit={handleSubmit}
                          >
                            {store.alias}
                            <input
                              type="hidden"
                              name="id"
                              defaultValue={store.id}
                            />
                          </form>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {store.subdomain}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-right text-sm text-gray-500">
                          <div className="ml-auto flex max-w-[200px] items-stretch overflow-hidden rounded-md border border-gray-300 focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                            <input
                              form={`${store.subdomain}_-_form`}
                              type="text"
                              name="shipping_rate_with_battery"
                              defaultValue={store.shipping_rate_with_battery}
                              className="block w-full border-0 px-3 py-2 text-right text-sm text-gray-900 placeholder-gray-500 focus:ring-0"
                            />
                            <div className="pointer-events-none flex items-center bg-gray-50 px-3 py-2">
                              <span className="text-sm text-gray-500">CNY</span>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-right text-sm text-gray-500">
                          <div className="ml-auto flex max-w-[200px] items-stretch overflow-hidden rounded-md border border-gray-300 focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                            <input
                              form={`${store.subdomain}_-_form`}
                              type="text"
                              name="shipping_rate_without_battery"
                              defaultValue={store.shipping_rate_without_battery}
                              className="block w-full border-0 px-3 py-2 text-right text-sm text-gray-900 placeholder-gray-500 focus:ring-0"
                            />
                            <div className="pointer-events-none flex items-center bg-gray-50 px-3 py-2">
                              <span className="text-sm text-gray-500">CNY</span>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-right text-sm text-gray-500">
                          <div className="ml-auto flex max-w-[200px] items-stretch overflow-hidden rounded-md border border-gray-300 focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                            <input
                              form={`${store.subdomain}_-_form`}
                              type="text"
                              name="transaction_costs_percentage"
                              defaultValue={store.transaction_costs_percentage}
                              className="block w-full border-0 px-3 py-2 text-right text-sm text-gray-900 placeholder-gray-500 focus:ring-0"
                            />
                            <div className="pointer-events-none flex items-center bg-gray-50 px-3 py-2">
                              <span className="text-sm text-gray-500">%</span>
                            </div>
                          </div>
                        </td>
                        <td className="min-w-[110px] whitespace-nowrap px-3 py-4 text-right text-sm text-gray-500">
                          <button
                            form={`${store.subdomain}_-_form`}
                            type="submit"
                            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-20"
                            {...(isEditLoading &&
                              parseInt(currentEditedStore) ===
                                parseInt(store.id) && {
                                disabled: true,
                              })}
                          >
                            {isEditLoading &&
                              parseInt(currentEditedStore) ===
                                parseInt(store.id) && (
                                <RefreshIcon className="-ml-0.5 mr-2 h-4 w-4 animate-spin" />
                              )}
                            Save
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
}
