import { useEffect, useState } from "react";
import Fancybox from "../../hooks/Fancybox";
import useShopifyProductData from "../../hooks/useShopifyProductData";
import Loading from "../Loading";
import SourceMediaElement from "./SourceMediaElement";

export default function SourceProductData({
  productId,
  store,
  setProductMedia,
  submitButton,
}) {
  const [isLoading, error, product, fetchData] = useShopifyProductData({
    productId,
    store,
  });
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [isChecked, setIsChecked] = useState([]);

  const handleCheckbox = (e) => {
    const { id, checked } = e.target;
    setIsChecked([...isChecked, id]);
    if (!checked) {
      setIsChecked(isChecked.filter((item) => item !== id));
    }
  };

  const handleSelectAll = () => {
    setIsCheckedAll(!isCheckedAll);
    setIsChecked(product.media.edges.map((media) => media.node.id));
    if (isCheckedAll) {
      setIsChecked([]);
    }
  };

  useEffect(() => {
    setIsCheckedAll(() => false);
  }, [productId, store]);

  useEffect(() => {
    product && setProductMedia(product.media.edges);
  }, [product, setProductMedia]);

  return (
    <Fancybox options={{ infinite: false }}>
      {isLoading ? (
        <div className="flex items-center gap-x-1 py-1">
          <Loading />
          <span className="text-sm text-indigo-600">
            Product data is loading ...
          </span>
        </div>
      ) : error ? (
        <p className="py-4 text-sm font-semibold text-red-600">
          Error: ${error.message}
        </p>
      ) : (
        product && (
          <>
            <div className="mt-4">
              <div className="font-semibold text-gray-900">
                {product.title} -{" "}
                <span
                  className={`text-sm font-normal ${
                    product.status === "ACTIVE"
                      ? "text-green-600"
                      : "text-red-600"
                  }`}
                >
                  {product.status}
                </span>
              </div>
              <div className="text-sm italic text-gray-500">
                {product.handle}
              </div>
              <div className="text-sm font-medium text-indigo-900">
                SKU(s):{" "}
                {product.variants.edges
                  .map((variant) => variant.node.sku)
                  .join(", ")}
              </div>
            </div>
            <div className="relative mb-3 flex min-h-[3rem] translate-x-[0.2rem] items-end justify-between">
              <div>{isChecked.length > 0 && submitButton}</div>
              <div className="flex items-center">
                <div className="mr-2 text-sm">
                  <label htmlFor="select-all" className="text-indigo-600">
                    Select all
                  </label>
                </div>
                <div className="flex h-5 items-center">
                  <input
                    id="select-all"
                    name="select-all"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    checked={isCheckedAll}
                    onChange={handleSelectAll}
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-3">
              {product.media.edges.map((media) => (
                <SourceMediaElement
                  key={media.node.id}
                  media={media}
                  isChecked={isChecked}
                  handleCheckbox={handleCheckbox}
                />
              ))}
            </div>
            <button
              type="button"
              className="mx-auto flex translate-y-2 items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={() => fetchData()}
            >
              Reload
            </button>
          </>
        )
      )}
    </Fancybox>
  );
}
