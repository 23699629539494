import axios from "axios";
import { useCallback, useEffect, useState } from "react";

export default function useShopifyProductData({ productId, store }) {
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    setError(null);
    setIsLoading(true);
    await axios
      .post(
        `/api/graphql/${store.subdomain}/${process.env.REACT_APP_SHOPIFY_API_VERSION}`,
        {
          query: `query {
            product(id: "${productId}") {
              id
              title
              status
              handle
              variants(first: 30) {
                edges {
                  node {
                    id
                    sku
                    media(first: 1) {
                      edges {
                        node {
                          ...fieldsForMediaTypes
                        }
                      }
                    }
                  }
                }
              }
              media(first: 30) {
                edges {
                  node {
                    ...fieldsForMediaTypes
                  }
                }
              }
            }
          }
          
          fragment fieldsForMediaTypes on Media {
            alt
            mediaContentType
            preview {
              image {
                id
                altText
                url
              }
            }
            status
            ... on Video {
              id
              sources {
                format
                height
                mimeType
                url
                width
              }
              originalSource {
                format
                height
                mimeType
                url
                width
              }
            }
            ... on ExternalVideo {
              id
              host
              originUrl
            }
            ... on Model3d {
              sources {
                format
                mimeType
                url
              }
              originalSource {
                format
                mimeType
                url
              }
            }
            ... on MediaImage {
              id
              mimeType
              image {
                altText
                url
              }
            }
          }`,
        }
      )
      .then(function (response) {
        if (response.data?.errors) {
          setError(
            response.data?.errors.map((error) => error.message).join(", ")
          );
        } else {
          setProduct(response.data.data.product);
        }
      })
      .catch(function (error) {
        setError(error);
      })
      .then(function () {
        setIsLoading(false);
      });
  }, [productId, store.subdomain]);

  useEffect(() => {
    productId && fetchData();
  }, [fetchData, productId]);

  return [isLoading, error, product, fetchData];
}
