import axios from "axios";
import { useEffect, useState } from "react";

export default function ManualOrderExport() {
  const [availableStores, setAvailableStores] = useState([]);

  const [selectedStores, setSelectedStores] = useState(new Set());

  const [emailString, setEmailString] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/api/order_exports", {
        store_ids: [...selectedStores],
        emails: emailString.split(";").map((email) => email.trim()),
      });
      alert(JSON.stringify(response.data));
    } catch (error) {
      alert(JSON.stringify(error?.response.data));
    }
  };

  useEffect(
    () => axios.get("/api/stores").then((res) => setAvailableStores(res.data)),
    []
  );

  return (
    <div className="container mx-auto max-w-7xl">
      <h1>Manual Order Export</h1>
      <form onSubmit={handleSubmit}>
        <fieldset>
          <legend>Stores:</legend>
          {availableStores.map((store) => (
            <label key={store.id}>
              <input
                type="checkbox"
                checked={selectedStores.has(store.id)}
                onChange={(e) => {
                  setSelectedStores((selectedStores) => {
                    const clonedSelectedStores = new Set(selectedStores);
                    e.target.checked
                      ? clonedSelectedStores.add(store.id)
                      : clonedSelectedStores.delete(store.id);
                    return clonedSelectedStores;
                  });
                }}
              />
              {store.alias}
            </label>
          ))}
        </fieldset>
        <label>
          Email (seperate with semicolon)
          <input
            type="text"
            value={emailString}
            onChange={(e) => setEmailString(e.target.value)}
          />
        </label>
        <button type="submit">Send to emails</button>
      </form>
    </div>
  );
}
