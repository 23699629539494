import axios from "axios";
import { useState } from "react";

export default function useShopifyMutateStatus() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const sendMutation = async (subdomain, productId, status, setStatus) => {
    setError(null);
    setIsLoading(true);
    await axios
      .post(
        `/api/graphql/${subdomain}/${process.env.REACT_APP_SHOPIFY_API_VERSION}`,
        {
          query: `mutation {
            productUpdate(
              input: {
                id: "${productId}"
                status: ${status === true ? "ACTIVE" : "DRAFT"}
              }
            ) {
              product {
                status
              }
              userErrors {
                field
                message
              }
            }
          }`,
        }
      )
      .then(function (response) {
        response.data?.errors
          ? setError(
              response.data?.errors.map((error) => error.message).join(", ")
            )
          : setStatus(
              response?.data?.data?.productUpdate?.product?.status === "ACTIVE"
                ? true
                : false
            );
      })
      .catch(function (error) {
        setError(error.message);
      })
      .finally(function () {
        setIsLoading(false);
      });
  };

  return [isLoading, error, sendMutation];
}
