import { RefreshIcon } from "@heroicons/react/outline";
import { useContext, useEffect } from "react";
import useExchange from "../hooks/useExchange";
import useSaveSettings from "../hooks/useSaveSettings";
import useSettings from "../hooks/useSettings";
import { MOContext } from "../pages/multistore-operations";
import InputGroup from "./InputGroup";
import Loading from "./Loading";

export default function MOSettings() {
  const { exchangeRates, setExchangeRates } = useContext(MOContext);
  const [isExchangeLoading, exchangeError] = useExchange();
  const {
    isLoading: isSettingsLoading,
    isError: settingsError,
    data: settings,
  } = useSettings();
  const [isSaveSettingsLoading, saveSettingsError, saveSettings] =
    useSaveSettings();

  function handleSubmit(event) {
    event.preventDefault();
    saveSettings(new FormData(event.currentTarget));
  }

  useEffect(() => {
    // when settings are ready, add them to the exchangeRate object
    !isSettingsLoading &&
      !settingsError &&
      settings?.CNY_manual &&
      setExchangeRates((existingData) => ({
        ...existingData,
        ...settings,
      }));
  }, [isSettingsLoading, setExchangeRates, settings, settingsError]);

  return (
    <form onSubmit={handleSubmit} className="divide-y">
      <div className="bg-gray-50 px-4 py-1 text-sm sm:flex sm:items-center sm:justify-end">
        <button
          type="submit"
          className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-indigo-900 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-20"
          {...(isSaveSettingsLoading && {
            disabled: true,
          })}
        >
          {isSaveSettingsLoading && (
            <RefreshIcon className="-ml-0.5 mr-2 h-4 w-4 animate-spin" />
          )}
          Save data
        </button>
      </div>
      <div className="p-4 text-sm text-gray-900">
        {isExchangeLoading || isSettingsLoading ? (
          <div className="flex items-center gap-x-1 px-4 py-2 sm:px-6">
            <Loading />
            <span className="ml-2 text-sm text-indigo-600 sm:ml-3">
              Retriving exchange rates ...
            </span>
          </div>
        ) : exchangeError || settingsError || saveSettingsError ? (
          <p className="px-4 py-2 text-sm font-semibold text-red-500 sm:px-6">
            Error: {exchangeError?.message} {settingsError?.message}{" "}
            {saveSettingsError?.message}
          </p>
        ) : exchangeRates?.date ? (
          <div className="space-y-4">
            <div className="flex items-center gap-x-1">
              <span>USD 1</span> <span>=</span>
              <InputGroup
                label="Manual rate"
                name="CNY_manual"
                leadingtext="CNY"
                value={exchangeRates.CNY_manual}
                onChange={(e) =>
                  setExchangeRates((existingData) => ({
                    ...existingData,
                    CNY_manual: e.target.value,
                  }))
                }
              />
              <span className="space-x-1">
                <span className="text-gray-500">CNY</span>
                <span>
                  {exchangeRates.CNY}
                  <span className="text-red-600">*</span>
                </span>
              </span>
            </div>

            <div>
              <div className="grid gap-y-3 sm:grid-cols-2">
                <div className="flex items-center gap-x-1">
                  <span>
                    GBP 1 = USD {(1 / exchangeRates.GBP).toFixed(4)}
                    <span className="text-red-600">*</span>
                  </span>
                  <span>+</span>
                  <div className="w-24">
                    <InputGroup
                      label="GBP spread"
                      name="GBP_spread"
                      trailingtext="%"
                      value={exchangeRates.GBP_spread}
                      onChange={(e) =>
                        setExchangeRates((existingData) => ({
                          ...existingData,
                          GBP_spread: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <span>=</span>
                  <span>
                    {(
                      parseFloat(1 / exchangeRates.GBP) *
                      ((100 + parseFloat(exchangeRates.GBP_spread)) / 100)
                    ).toFixed(6)}
                  </span>
                </div>

                <div className="flex items-center gap-x-1">
                  <span>
                    EUR 1 = USD {(1 / exchangeRates.EUR).toFixed(4)}
                    <span className="text-red-600">*</span>
                  </span>
                  <span>+</span>
                  <div className="w-24">
                    <InputGroup
                      label="EUR spread"
                      name="EUR_spread"
                      trailingtext="%"
                      value={exchangeRates.EUR_spread}
                      onChange={(e) =>
                        setExchangeRates((existingData) => ({
                          ...existingData,
                          EUR_spread: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <span>=</span>
                  <span>
                    {(
                      parseFloat(1 / exchangeRates.EUR) *
                      ((100 + parseFloat(exchangeRates.EUR_spread)) / 100)
                    ).toFixed(4)}
                  </span>
                </div>

                <div className="flex items-center gap-x-1">
                  <span>
                    USD 1 = BRL {exchangeRates.BRL}
                    <span className="text-red-600">*</span>
                  </span>
                  <span>+</span>
                  <div className="w-24">
                    <InputGroup
                      label="BRL spread"
                      name="BRL_spread"
                      trailingtext="%"
                      value={exchangeRates.BRL_spread}
                      onChange={(e) =>
                        setExchangeRates((existingData) => ({
                          ...existingData,
                          BRL_spread: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <span>=</span>
                  <span>
                    {(
                      parseFloat(exchangeRates.BRL) *
                      ((100 + parseFloat(exchangeRates.BRL_spread)) / 100)
                    ).toFixed(4)}
                  </span>
                </div>

                <div className="flex items-center gap-x-1">
                  <span>
                    USD 1 = MXN {exchangeRates.MXN}
                    <span className="text-red-600">*</span>
                  </span>
                  <span>+</span>
                  <div className="w-24">
                    <InputGroup
                      label="MXN spread"
                      name="MXN_spread"
                      trailingtext="%"
                      value={exchangeRates.MXN_spread}
                      onChange={(e) =>
                        setExchangeRates((existingData) => ({
                          ...existingData,
                          MXN_spread: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <span>=</span>
                  <span>
                    {(
                      parseFloat(exchangeRates.MXN) *
                      ((100 + parseFloat(exchangeRates.MXN_spread)) / 100)
                    ).toFixed(4)}
                  </span>
                </div>
              </div>

              <p className="-mb-2 mt-1 space-x-1 text-right text-xs italic text-gray-500">
                <span>
                  <span className="text-red-600">*</span>source: European
                  Central Bank on
                </span>
                <span>
                  {exchangeRates?.date
                    ? exchangeRates?.date.split("-").reverse().join("/")
                    : null}
                </span>
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </form>
  );
}
