import { SearchIcon } from "@heroicons/react/outline";
import { gql } from "graphql-request";
import Fancybox from "../hooks/Fancybox";
import useUnitCost from "../hooks/useUnitCost";
import useGraphqlQuery from "../hooks/useGraphqlQuery";
import useInternationalShippingCostInCny from "../hooks/useInternationalShippingCostInCny";
import useLatestPurchaseOrderCache from "../hooks/useLatestPurchaseOrderCache";
import useMarginPercentage from "../hooks/useMarginPercentage";
import usePriceInUsd from "../hooks/usePriceInUsd";
import usePurchasePriceFallback from "../hooks/usePurchasePriceFallback";
import useStockExportCache from "../hooks/useStockExportCache";
import { calculateDiscount, margin } from "../utils/helpers";
import { useFormikContext } from "formik";
import useComboSku from "../hooks/useComboSku";
import useComboSkuTotalProductCostInCny from "../hooks/useComboSkuTotalProductCostInCny";
import useComboSkuTotalLatestPurchaseOrderCacheUnitPrice from "../hooks/useComboSkuTotalLatestPurchaseOrderCacheUnitPrice";
import { useState, useEffect } from "react";

export default function ({ product, variant, store }) {
  const { data: priceInUsd = null } = usePriceInUsd(store, variant?.price);

  const { data: marginPercentage = null } = useMarginPercentage(
    variant?.sku,
    store?.id,
    variant?.price
  );

  const { data: stockExportCache = null } = useStockExportCache(variant?.sku);
  const { data: latestPurchaseOrderCache = null } = useLatestPurchaseOrderCache(
    variant?.sku
  );

  const { data: purchasePriceFallback = null } = usePurchasePriceFallback(
    variant?.sku
  );

  const { data: unitCostInUsd = null } = useUnitCost(
    store,
    variant?.sku,
    "USD"
  );
  const { data: unitCostInCny = null } = useUnitCost(
    store,
    variant?.sku,
    "CNY"
  );

  const { data: internationalShippingCostInCny = null } =
    useInternationalShippingCostInCny(store, variant?.sku);

  const { values, setFieldValue } = useFormikContext();

  const roas = marginPercentage && 1 / (marginPercentage / 100);

  // check if the sku is combo sku
  const { data: comboSku } = useComboSku(variant?.sku);
  const { data: comboSkuTotalProductCostInCny = null } = useComboSkuTotalProductCostInCny(comboSku?.sku);
  const { data: comboSkuTotalLatestPurchaseOrderCacheUnitPrice = null } = useComboSkuTotalLatestPurchaseOrderCacheUnitPrice(comboSku?.sku);

  const productCostInCny = (
    comboSku ?
      comboSkuTotalProductCostInCny :
      (
        latestPurchaseOrderCache?.pivot?.data ?
          (
            parseFloat(latestPurchaseOrderCache?.pivot?.data?.采购单价) +
            parseFloat(latestPurchaseOrderCache?.pivot?.data?.运费单价)
          ).toFixed(2)
          : purchasePriceFallback?.price
      )
  ) || 0;

  const purchaseUnitPrice = (
    comboSku ?
      comboSkuTotalLatestPurchaseOrderCacheUnitPrice?.采购单价 :
      latestPurchaseOrderCache?.pivot?.data?.采购单价
  ) || 0;

  const shippingUnitPrice = (
    comboSku ?
      comboSkuTotalLatestPurchaseOrderCacheUnitPrice?.运费单价 :
      latestPurchaseOrderCache?.pivot?.data?.运费单价
  ) || 0;

  const [status, setStatus] = useState('');
  const [classificationInfo, setClassificationInfo] = useState('');
  const [withBattery, setWithBattery] = useState('');

  useEffect(() => {
    if (comboSku && comboSku.stock_export_caches) {
      const statusList = comboSku?.stock_export_caches?.map(product => product?.data?.状态) || [];
      setStatus(
        statusList.includes('停止销售') ?
          '停止销售'
          :
          statusList.includes('商品清仓') ? '商品清仓' : '正常销售'
      );

      const classificationInfoList = comboSku?.stock_export_caches?.map(product => product?.data?.分类信息) || [];
      const classificationInfoString = classificationInfoList.reduce((prev, curr) => {
        return prev || (curr.indexOf('带电') > -1 && curr.indexOf('不带电') < 0) || (curr.indexOf('带磁') > -1 && curr.indexOf('不带磁') < 0)
      }, false) ? '带电&带磁' : '不带电&不带磁';
      setClassificationInfo(classificationInfoString);

      setWithBattery(classificationInfoString === '带电&带磁' ? '是' : '否');
    }
  }, [comboSku]);

  return (
    <tr>
      <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
        <div className="flex items-center space-x-3 pr-8">
          <Fancybox options={{ infinite: false }}>
            <button
              data-fancybox={variant?.id}
              data-src={product?.featuredImage?.url}
              className="group relative h-full max-h-[2rem] w-full max-w-[2rem] shrink-0 overflow-hidden rounded-full"
            >
              <img
                src={product?.featuredImage?.url}
                alt="from Mabang"
                className="h-full max-h-[2rem] w-full max-w-[2rem] rounded-full object-cover object-center transition duration-300 ease-in-out group-hover:scale-125"
              />
              <div className="absolute inset-0 bg-indigo-900 opacity-25 transition duration-300 ease-in-out group-hover:opacity-0"></div>
              <SearchIcon className="absolute inset-1/2 h-4 w-4 -translate-x-1/2 -translate-y-1/2 stroke-gray-100 text-indigo-600 transition duration-300 ease-in-out group-hover:opacity-0" />
            </button>
          </Fancybox>
          <div className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
            {product?.title}
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
        {variant?.title}
      </td>
      <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
        {variant?.sku}
      </td>
      <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
        <input
          type={"number"}
          step={0.01}
          min={0}
          defaultValue={variant?.price}
          onChange={(e) => {
            setFieldValue(`${store?.id}.${variant?.id}.price`, e.target.value);
          }}
        />
      </td>
      <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
        <input
          type={"number"}
          step={0.01}
          min={0}
          defaultValue={variant?.compareAtPrice}
          onChange={(e) => {
            setFieldValue(
              `${store?.id}.${variant?.id}.compareAtPrice`,
              e.target.value
            );
          }}
        />
      </td>
      <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
        {calculateDiscount(variant?.price, variant?.compareAtPrice)?.toFixed(2)}
        %{" "}
      </td>
      <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
        {priceInUsd?.toFixed(2)}
      </td>
      <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
        {((unitCostInUsd * 100) / priceInUsd)?.toFixed(2)}%
      </td>
      <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
        {marginPercentage?.toFixed(2)}%
      </td>
      <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
        {roas?.toFixed(2)}
      </td>
      <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
        {comboSku ? status : stockExportCache?.data?.状态}
      </td>
      <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
        {comboSku ? classificationInfo : stockExportCache?.data?.分类信息}
      </td>
      <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
        {purchaseUnitPrice &&
          `${parseFloat(
            purchaseUnitPrice
          ).toFixed(2)} (${(
            (purchaseUnitPrice * 100) /
            unitCostInCny
          ).toFixed(2)}%)`}
      </td>
      <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
        {shippingUnitPrice &&
          `${shippingUnitPrice} (${(
            (shippingUnitPrice * 100) /
            unitCostInCny
          ).toFixed(2)}%)`}
      </td>
      <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
        {productCostInCny &&
          `${productCostInCny} (${(
            (productCostInCny * 100) /
            unitCostInCny
          ).toFixed(2)}%)`}
      </td>
      <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
        {`${internationalShippingCostInCny?.toFixed(2)} (${(
          (internationalShippingCostInCny * 100) /
          unitCostInCny
        ).toFixed(2)}%)`}
      </td>
      <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
        {unitCostInCny?.toFixed(2)}
      </td>
      <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
        {unitCostInUsd?.toFixed(2)}
      </td>
      <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
        {comboSku ? withBattery : stockExportCache?.data?.是否带电池}
      </td>
      <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
        {comboSku ? comboSku?.data?.组合重量 : stockExportCache?.data?.重量}
      </td>
      <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
        {comboSku ? comboSku?.data?.体积重 : stockExportCache?.data?.体积重}
      </td>
      <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
        {comboSku ? comboSku?.data?.长 : stockExportCache?.data?.长}
      </td>
      <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
        {comboSku ? comboSku?.data?.宽 : stockExportCache?.data?.宽}
      </td>
      <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
        {comboSku ? comboSku?.data?.高 : stockExportCache?.data?.高}
      </td>
    </tr>
  );
}
