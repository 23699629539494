import { useContext } from "react";
import { MOContext } from "../pages/multistore-operations";
import DisplayVariantData from "./DisplayVariantData";
import InputGroup from "./InputGroup";
import MutateStatus from "./MutateStatus";

export default function DisplayShopifyData({ product, currencyCode, store }) {
  const { mabangData } = useContext(MOContext);
  const numberOfVariants = product.node?.hasOnlyDefaultVariant
    ? 1
    : product.node?.variants?.edges.length;

  return (
    <div className="p-4 text-sm text-gray-900">
      <div className="sm:grid sm:grid-cols-2 sm:justify-end sm:gap-4">
        <div className="sm:flex sm:gap-x-4">
          <div className="grow-0 space-y-2">
            <MutateStatus
              initialStatus={product.node?.status}
              storeSubdomain={store.subdomain}
              productId={product.node?.id}
            />

            <div className="sr-only text-gray-500">image</div>
            <div className="text-gray-900">
              <img
                src={
                  product.node?.featuredImage?.url
                    ? product.node?.featuredImage?.url
                    : "/No_Image_Available.jpg"
                }
                alt="from Mabang"
                className="max-w-[8rem] rounded-lg border border-indigo-600"
              />
            </div>
          </div>
          <div className="grow">
            <div>
              <div>
                <div className="sr-only text-gray-500">product title</div>
                <div className="text-gray-900">
                  <InputGroup
                    label="title"
                    name={`${product.node?.id}_-_title`}
                    defaultValue={product.node?.title}
                  />
                </div>
              </div>
              <div className="italic sm:space-x-1">
                <div className="text-gray-500 sm:inline-block">handle:</div>
                <div className="text-gray-900 sm:inline-block">
                  {product.node?.handle}
                </div>
              </div>
            </div>
            <div>
              <div className="sr-only text-gray-500">
                metafields (Google namespace)
              </div>
              <div className="mt-3 grid grid-cols-2 gap-y-3 gap-x-2 text-gray-900">
                {
                  /** sometimes custom_label_4 (or even other custom labels) is not there
                    because it has never been used before. If it's missing then I will
                    add it "manually" and then figure out how to handle it (or them)
                    inside retructureFormDataForMutation() function in helpers.js file
                    */
                  [
                    "custom_label_0",
                    "custom_label_1",
                    "custom_label_2",
                    "custom_label_3",
                    "custom_label_4",
                  ].map((custom_label, index) => {
                    if (
                      product.node?.metafields?.edges.some(
                        (metafield) => metafield.node?.key === custom_label
                      )
                    ) {
                      const metaObj = product.node?.metafields?.edges.find(
                        (metafield) => metafield.node?.key === custom_label
                      );
                      return (
                        <InputGroup
                          key={index}
                          label={metaObj.node?.key.split("_").join(" ")}
                          name={`${product.node?.id}_-_${metaObj.node?.id}`}
                          defaultValue={metaObj.node?.value}
                          required={true}
                        />
                      );
                    } else {
                      return (
                        <InputGroup
                          key={index}
                          label={custom_label.split("_").join(" ")}
                          name={`${product.node?.id}_-_${custom_label}`}
                          defaultValue={0}
                          required={true}
                        />
                      );
                    }
                  })
                }
              </div>
            </div>
          </div>
        </div>

        {numberOfVariants > 1 && (
          <div className="ml-2 -mb-2 text-gray-500 sm:col-span-2">
            {`${product.node?.variants?.edges.length} variants`}
          </div>
        )}

        {product.node?.variants?.edges.map((variant, index, variants) =>
          /**
              Unfortunately Mabang cannot be queried in parallel so here is
              the solution to serialise the requests from different SKUs
              from a single store.
              - DisplayVariantData has a custom hook that fetches data from Mabang.
              - For the first variant, DisplayVariantData can be rendered.
              - For the following variants, I check first if the previous fetching
                has been completed checking if the context mabangData variable has 
                some data inside (i.e.: the actual SKU).
              - If some data has been found that means the fetching as been completed and I can
                proceed to render the DisplayVariantData for the next variant.
               */
          index === 0 ? (
            <div key={index}>
              <DisplayVariantData
                product={product}
                variant={variant}
                currencyCode={currencyCode}
                store={store}
              />
            </div>
          ) : mabangData[variants[index - 1].node.sku] &&
            mabangData[variants[index - 1].node.sku].sku ? (
            <div key={index}>
              <DisplayVariantData
                product={product}
                variant={variant}
                currencyCode={currencyCode}
                store={store}
              />
            </div>
          ) : null
        )}
      </div>
    </div>
  );
}
