import { ArrowSmRightIcon } from "@heroicons/react/solid";
import { useContext, useEffect } from "react";
import { MOContext } from "../pages/multistore-operations";
import { volume, volumetricWeightInKg, weightInKg } from "../utils/helpers";
import InputGroup from "./InputGroup";

export default function DisplayMabangData({ data }) {
  const { roiData, setRoiData } = useContext(MOContext);

  useEffect(() => {
    setRoiData((roiData) => ({
      ...roiData,
      [`${data.sku}_-_purchasePrice`]: data.purchasePrice,
    }));
  }, [data.purchasePrice, data.sku, setRoiData]);

  return (
    <div className="space-y-2 sm:flex sm:justify-between sm:space-x-4 sm:space-y-0">
      <div>
        <div className="sr-only text-gray-500">image</div>
        <div className="text-gray-900">
          <img
            src={data.pictureUrl ? data.pictureUrl : "/No_Image_Available.jpg"}
            alt="from Mabang"
            className="max-w-[8rem] rounded-lg border border-indigo-600"
          />
        </div>
      </div>
      <div className="grow space-y-2">
        <div>
          <div className="sr-only text-gray-500">chinese name</div>
          <div className="text-gray-900">{data.chineseName}</div>
        </div>
        {data.labels.length > 0 ? (
          <div>
            <div className="text-gray-500">labels</div>
            <div className="text-gray-900">{data.labels.join(", ")}</div>
          </div>
        ) : null}
        <div className="pt-2">
          {roiData[`${data.sku}_-_purchasePrice`] && (
            <InputGroup
              bg="bg-gray-50"
              label={`purchase price: CNY ${data.purchasePrice}`}
              name={`${data.sku}_-_purchasePrice`}
              leadingtext={"CNY"}
              value={roiData[`${data.sku}_-_purchasePrice`]}
              onChange={(e) =>
                setRoiData((roiData) => ({
                  ...roiData,
                  [`${data.sku}_-_purchasePrice`]: e.target.value,
                }))
              }
            />
          )}
        </div>
        <div>
          <div className="sr-only text-gray-500">stock levels</div>
          <div className="sm:flex sm:justify-between sm:gap-x-3">
            <div className="space-x-1">
              <div className="text-gray-500 sm:inline-block">QCed:</div>
              <div className="text-gray-900 sm:inline-block">
                {data.stockLevels.qced}
              </div>
            </div>
            <div className="space-x-1">
              <div className="text-gray-500 sm:inline-block">in Transit:</div>
              <div className="text-gray-900 sm:inline-block">
                {data.stockLevels.inTransit}
              </div>
            </div>
            <div className="space-x-1">
              <div className="text-gray-500 sm:inline-block">
                to Be Shipped:
              </div>
              <div className="text-gray-900 sm:inline-block">
                {data.stockLevels.toBeShipped}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="text-gray-500">dimensions (cm)</div>
          <div className="text-gray-900">
            L: {data.dimensions?.length}, W: {data.dimensions?.width}, H:{" "}
            {data.dimensions?.height} = V: {volume(data)} cm&#179;
          </div>
        </div>
        <div className="grid grid-cols-2">
          <div>
            <div className="text-gray-500">
              {data.weightBuffer > 0
                ? `buffered weight (+${data.weightBuffer}g)`
                : "weight"}
            </div>
            <div className="flex items-end gap-x-1 text-gray-900">
              <span>{data.weight} g</span>
              <ArrowSmRightIcon className="h-4 w-4" />
              <span>{weightInKg(data)} kg</span>
            </div>
          </div>
          <div>
            <div>
              <div className="text-gray-500">volumetric w. V/8000 [V/6000]</div>
              <div className="text-gray-900">
                {volumetricWeightInKg(data, 8000).toFixed(4)} kg [
                {volumetricWeightInKg(data, 6000).toFixed(4)} kg]
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
