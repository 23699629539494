import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { DateTime } from "luxon";
import useHistory from "../hooks/useHistory";
import Loading from "./Loading";

export default function History({
  sku,
  storeAlias,
  productTitle,
  storeSubdomain,
}) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isHistoryLoading, historyError, history, fetchHistory] = useHistory();

  return (
    <>
      <button
        type="button"
        className="col-span-2 ml-auto mt-1 inline-flex items-end gap-x-1 text-xs font-medium text-indigo-600"
        onClick={() => {
          fetchHistory(sku);
          setIsDialogOpen(true);
        }}
      >
        show historical data
        <ChevronRightIcon className="h-4 w-4" />
      </button>
      <Transition appear show={isDialogOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40"
          onClose={() => setIsDialogOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-indigo-900 bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="max-h-[600px] w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Historical data for {sku}
                  </Dialog.Title>
                  <Dialog.Description className="italic text-gray-500">
                    {storeAlias}: {productTitle}
                  </Dialog.Description>
                  <div className="-mx-1 mt-2 max-h-[450px] overflow-hidden overflow-y-auto py-2 px-1">
                    {isHistoryLoading ? (
                      <div className="flex items-center gap-x-1">
                        <Loading />
                        <p className="text-indigo-600">history is loading...</p>
                      </div>
                    ) : historyError ? (
                      <div className="space-y-1">
                        <p className="text-xs font-semibold text-red-500">
                          {historyError}
                        </p>
                      </div>
                    ) : history &&
                      history.filter(
                        (entry) =>
                          JSON.parse(entry.shop_data[0]).store ===
                          storeSubdomain
                      ).length > 0 ? (
                      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th
                                    scope="col"
                                    className="p-2 text-left text-xs font-semibold capitalize text-gray-900"
                                  >
                                    date (HKT time)
                                  </th>
                                  <th
                                    scope="col"
                                    className="p-2 text-right text-xs font-semibold capitalize text-gray-900"
                                  >
                                    price
                                  </th>
                                  <th
                                    scope="col"
                                    className="p-2 text-right text-xs font-semibold capitalize text-gray-900"
                                  >
                                    compared at price
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200 bg-white">
                                {history
                                  .filter(
                                    (entry) =>
                                      JSON.parse(entry.shop_data[0]).store ===
                                      storeSubdomain
                                  )
                                  .map((entry, index) => (
                                    <tr key={index}>
                                      <td className="whitespace-nowrap p-2 text-xs text-gray-500">
                                        <div className="space-x-1">
                                          <span>
                                            {DateTime.fromISO(
                                              entry.created_at,
                                              {
                                                zone: "UTC",
                                              }
                                            )
                                              .setZone("Asia/Hong_Kong")
                                              .toLocaleString(
                                                DateTime.DATE_MED_WITH_WEEKDAY
                                              )}
                                          </span>
                                          <span>@</span>
                                          <span>
                                            {DateTime.fromISO(
                                              entry.created_at,
                                              {
                                                zone: "UTC",
                                              }
                                            )
                                              .setZone("Asia/Hong_Kong")
                                              .toLocaleString(
                                                DateTime.TIME_24_WITH_SECONDS
                                              )}
                                          </span>
                                        </div>
                                      </td>
                                      <td className="whitespace-nowrap p-2 text-right text-xs text-gray-500">
                                        {JSON.parse(entry.shop_data[0]).price}
                                      </td>
                                      <td className="whitespace-nowrap p-2 text-right text-xs text-gray-500">
                                        {
                                          JSON.parse(entry.shop_data[0])
                                            .compareAtPrice
                                        }
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                              <tbody className="divide-y divide-gray-200 bg-white">
                                <tr>
                                  <td className="whitespace-nowrap p-2 text-sm text-gray-900">
                                    No historical data found for the selected
                                    SKU.
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="mt-4 text-right">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-sm font-medium text-indigo-900 hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                      onClick={() => setIsDialogOpen(false)}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
