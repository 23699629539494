import axios from "axios";
import { useState } from "react";
import Alert from "../components/Alert";

export default function ManageOdaniShippingFiles() {
  const generateSubmitHandler = (postEndpoint) => {
    return async (event) => {
      event.preventDefault();
      try {
        await axios.post(postEndpoint, new FormData(event.target));
        setResponseAlert(<Alert>Success!</Alert>);
      } catch (e) {
        setResponseAlert(<Alert type="error">{e.response.data.message}</Alert>);
      }
    };
  };
  const [ResponseAlert, setResponseAlert] = useState();
  return (
    <div className="container mx-auto max-w-7xl">
      {ResponseAlert}
      <form
        onSubmit={generateSubmitHandler("/api/odani-warehouse-quantity/upload")}
        enctype="multipart/form-data"
      >
        <h1>Odani Sku List</h1>
        <input type="file" name="file" />
        <button type="submit">Upload</button>
        <a
          href={`${process.env.REACT_APP_BACKEND_PROTOCAL}://${process.env.REACT_APP_BACKEND_DOMAIN}/api/odani-warehouse-quantity/download`}
          className="ml-1"
        >
          Download
        </a>
      </form>
      <form onSubmit={generateSubmitHandler("/api/shipping-rate-table")}>
        <h1>Shipping Rate Table</h1>
        <input type="file" name="file" />
        <button type="submit">Upload</button>
      </form>
      <form onSubmit={generateSubmitHandler("/api/purchase-price-fallback")}>
        <h1>Purchase Price Fallback</h1>
        <input type="file" name="file" />
        <button type="submit">Upload</button>
      </form>
    </div>
  );
}
