import useSWR from "swr";
import { statefulRestfulFetcher } from "../utils/fetchers";

export default function (sku) {
  const { data, error } = useSWR(
    sku && `/api/stock-export-caches/${sku}/odani-warehouse-quantity`,
    statefulRestfulFetcher
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
}
