// https://reactrouter.com/web/example/auth-workflow

import { Link, Route, useLocation } from "react-router-dom";
import { useAuth } from "./auth";

const ProtectedRoute = ({ children, ...rest }) => {
  let auth = useAuth();
  const location = useLocation();

  return (
    <Route {...rest}>
      {auth.user ? (
        auth.user.email_verified_at ? (
          children
        ) : (
          <h1>Please verify your email first.</h1>
        )
      ) : (
        <div className="flex space-x-2">
          <Link
            to={{
              pathname: "/login",
              state: { from: location }, //pass current location to login so it login could replace itself's record in history with this path.
            }}
            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium capitalize text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            login
          </Link>
          <Link
            to={{
              pathname: "/register",
              state: { from: location }, //same as above
            }}
            className="inline-flex items-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-sm font-medium capitalize text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            register
          </Link>
        </div>
      )}
    </Route>
  );
};

export default ProtectedRoute;
