// use-auth implementation from https://usehooks.com/useAuth/

import { createContext, useContext, useEffect } from "react";
import { useLocalStorage } from "react-use";
const axios = require("axios").default;
axios.defaults.withCredentials = true;
axios.defaults.baseURL = `${process.env.REACT_APP_BACKEND_PROTOCAL}://${process.env.REACT_APP_BACKEND_DOMAIN}`;
axios.defaults.headers.common["Accept"] = "application/json"; //although not documented, without this laravel will response with a redirect to /home which will cause execption because of CORS policy.

const authContext = createContext();

export const useAuth = () => {
  return useContext(authContext); //You either export useAuth or export authContext for other component to use the context. The guide chose the former way.
};

export function ProvideAuth({ children }) {
  const auth = useProvideAuth(); //pass the custom hook as context value
  // dataflow: any children can use useAuth to get the auth hook object with user and sign-in related methods.
  // calling those method will trigger setUser, and thus updating the user.
  // this could ensure only one auth state is used and shared globally.
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useLocalStorage("user");

  const signin = async (input) => {
    await axios.get("/sanctum/csrf-cookie");
    await axios.post("/login", input);
    await updateUser();
  };
  const signout = async () => {
    await axios.post("/logout");
    await updateUser();
  };

  const updateUser = async () => {
    try {
      const response = await axios.get("/api/user");
      setUser(response.data);
    } catch (error) {
      if (error.response?.status === 401) {
        setUser(null);
      } else {
        throw error;
      }
    }
  };

  const register = async ({
    name,
    email,
    password,
    "password-confirmation": password_confirmation,
  }) => {
    await axios.post("/register", {
      name,
      email,
      password,
      password_confirmation,
    });
    await updateUser();
  };
  useEffect(() => {
    // logic that check for current login status, or handle additional logic like cookies expiring
    // the whole hook is called when ProvideAuth is mount, which mean should be only mounted once,
    // so the effect should also be only called once if no dependencies.
    updateUser();
  }, []);
  return {
    user,
    signin,
    signout,
    register,
  };
}
