import axios from "axios";
import { useState, useEffect, useContext, useCallback } from "react";
import { MOContext } from "../pages/multistore-operations";

export default function useShopifyData({ selectedSku, store }) {
  const { setShopifyData, setRoiData } = useContext(MOContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const setDataForRoiCalculation = useCallback(
    async (response) => {
      // getting the price for each variant of each product
      if (response.data?.products?.edges.length > 0) {
        response.data?.products?.edges.forEach((product) => {
          product.node?.variants?.edges.forEach((variant) => {
            setRoiData((roiData) => ({
              ...roiData,
              [`${store.subdomain}_-_${product.node?.id}_-_${variant.node?.id}_-_price`]:
                variant.node?.price,
            }));
          });
        });
      }
    },
    [setRoiData, store.subdomain]
  );

  const fetchData = useCallback(async () => {
    setError(null);
    setIsLoading(true);
    await axios
      .post(
        `/api/graphql/${store.subdomain}/${process.env.REACT_APP_SHOPIFY_API_VERSION}`,
        {
          query: `query {
              shop {
                name
                currencyCode
              }
              products(first: 10, query: "sku:${selectedSku.sku}") {
                edges {
                  node {
                    id
                    title
                    onlineStoreUrl
                    handle
                    status
                    hasOnlyDefaultVariant
                    featuredImage {
                      url(transform: { maxWidth: 500 })
                    }
                    metafields(first: 20, namespace: "google") {
                      edges {
                        node {
                          id
                          key
                          value
                          updatedAt
                          namespace
                        }
                      }
                    }
                    variants(first: 30) {
                      edges {
                        node {
                          id
                          title
                          sku
                          price
                          compareAtPrice
                          inventoryPolicy
                        }
                      }
                    }
                  }
                }
              }
            }`,
        }
      )
      .then(function (response) {
        setDataForRoiCalculation(response.data);
        setShopifyData((previousShopifyData) => ({
          ...previousShopifyData,
          [store.subdomain]: response.data,
        }));
      })
      .catch(function (error) {
        setError(error);
      })
      .finally(function () {
        setIsLoading(false);
      });
  }, [
    selectedSku.sku,
    setDataForRoiCalculation,
    setShopifyData,
    store.subdomain,
  ]);

  useEffect(() => {
    if (selectedSku) fetchData();
  }, [fetchData, selectedSku]);

  return [isLoading, error, fetchData];
}
