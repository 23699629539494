import axios from "axios";
import { useState } from "react";

export default function useEditStore() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const editStore = async (storeId, formData) => {
    const formDataObj = {};
    formData.forEach((value, key) => (formDataObj[key] = value));

    setError(null);
    setIsLoading(true);

    await axios
      .put(`/api/stores/${storeId}`, formDataObj)
      .catch(function (error) {
        setError(error.response);
      })
      .finally(function () {
        setIsLoading(false);
      });
  };

  return [isLoading, error, setError, editStore];
}
