import axios from "axios";
import { useState } from "react";

export default function useShopifyAddMedia() {
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const sendMutation = async (subdomain, productId, mediaCollection) => {
    setError(null);
    setIsLoading(true);
    await axios
      .post(
        `/api/graphql/${subdomain}/${process.env.REACT_APP_SHOPIFY_API_VERSION}`,
        {
          query: `mutation productCreateMedia {
            productCreateMedia(
              productId: "${productId}"
              media: [
                ${mediaCollection.map(
                  (media) => `
                  {
                    originalSource: "${media.originalSource}"
                    alt: "${media.alt}"
                    mediaContentType: ${media.mediaContentType}
                  },
                `
                )}
              ]
            ) {
              media {
                ...fieldsForMediaTypes
                mediaErrors {
                  code
                  details
                  message
                }
                mediaWarnings {
                  code
                  message
                }
              }
              product {
                id
              }
              mediaUserErrors {
                code
                field
                message
              }
            }
          }
          
          fragment fieldsForMediaTypes on Media {
            alt
            mediaContentType
            preview {
              image {
                id
              }
            }
            status
            ... on Video {
              id
              sources {
                format
                height
                mimeType
                url
                width
              }
            }
            ... on ExternalVideo {
              id
              host
              originUrl
            }
            ... on Model3d {
              sources {
                format
                mimeType
                url
              }
              boundingBox {
                size {
                  x
                  y
                  z
                }
              }
            }
          }`,
        }
      )
      .then(function (response) {
        console.info("useShopifyAddMedia response", response.data);
        response.data?.errors
          ? setError(
              response.data?.errors.map((error) => error.message).join(", ")
            )
          : setResponse(response?.data?.data);
      })
      .catch(function (error) {
        setError(error.message);
      })
      .finally(function () {
        setIsLoading(false);
      });
  };

  return [isLoading, error, sendMutation, response];
}
