import { Combobox } from "@headlessui/react";
import classNames from "classnames";
import { useState } from "react";
import useDebounce from "../hooks/useDebounce";
import useProductExportCaches from "../hooks/useProductExportCaches";
import Loading from "./Loading";

export default function ({ selectedProductExportCache, onSelect }) {
  const [searchSku, setSearchSku] = useState("");
  const debouncedSearchSku = useDebounce(searchSku, 500);
  const {
    response: { data: productExportCaches } = {},
    isLoading: productExportCachesIsLoading,
    isError: productExportCachesIsError,
  } = useProductExportCaches(debouncedSearchSku);

  return (
    <Combobox
      value={selectedProductExportCache}
      onChange={onSelect}
      as="div"
      className="relative mx-auto max-w-sm"
    >
      <div className="relative">
        <Combobox.Input
          onChange={(event) => {
            setSearchSku(event.target.value);
          }}
          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 uppercase text-gray-900 shadow-sm placeholder:normal-case placeholder:text-gray-500 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
          placeholder="Type SKU..."
          displayValue={(productExportCache) => productExportCache?.sku}
        />
        {productExportCachesIsLoading && debouncedSearchSku && (
          <div className="absolute top-1/2 right-3 -translate-y-1/2">
            <Loading />
          </div>
        )}
      </div>
      {productExportCachesIsError && (
        <p className="p-4 pb-0 text-sm font-semibold text-red-500">
          Error: ${productExportCachesIsError.message}
        </p>
      )}
      {searchSku &&
        productExportCaches?.length === 0 &&
        !productExportCachesIsLoading &&
        !productExportCachesIsError && (
          <p className="p-4 pb-0 text-sm font-semibold text-red-500">
            No SKU found.
          </p>
        )}
      {searchSku &&
        productExportCaches?.length > 0 &&
        !productExportCachesIsLoading &&
        !productExportCachesIsError && (
          <Combobox.Options className="absolute z-20 mt-1 max-h-80 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {productExportCaches.map((productExportCache) => (
              <Combobox.Option
                key={productExportCache.id}
                value={productExportCache}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9 uppercase",
                    active ? "bg-indigo-600 text-white" : "text-gray-900"
                  )
                }
              >
                {productExportCache.sku}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
    </Combobox>
  );
}
