import useSWR from "swr";
import { statefulRestfulFetcher } from "../utils/fetchers";

export default function (params) {
  const { data, error, isValidating, mutate } = useSWR(
    ["/api/tags", params],
    statefulRestfulFetcher
  );

  return {
    data,
    isLoading: !error && !data,
    error,
    isValidating,
    mutate,
  };
}
