import { useEffect, useState } from "react";
import SourceProductData from "../components/product-data-migration/SourceProductData";
import Heading from "../components/Heading";
import Loading from "../components/Loading";
import SearchShopify from "../components/product-data-migration/SearchShopify";
import useStores from "../hooks/useStores";
import DestinationProductData from "../components/product-data-migration/DestinationProductData";
import { RefreshIcon } from "@heroicons/react/outline";
import useShopifyAddMedia from "../hooks/product-data-migration/useShopifyAddMedia";
import Alert from "../components/Alert";

export default function ProductDataMigration() {
  const [sourceProductId, setSourceProductId] = useState(null);
  const [sourceStore, setSourceStore] = useState(null);
  const [sourceProductMedia, setSourceProductMedia] = useState([]);
  const [destinationProductId, setDestinationProductId] = useState(null);
  const [destinationStore, setDestinationStore] = useState(null);
  const [stores, storesLoading, storesError] = useStores();
  const [isAddingMedia, errorAddMedia, addMedia, addMediaResponse] =
    useShopifyAddMedia();

  const handleMigration = (event) => {
    event.preventDefault();
    const mediaCollection = [];
    const submittedData = new FormData(event.currentTarget);
    for (let mediaId of submittedData.keys()) {
      const mediaFound = sourceProductMedia.find(
        (media) => media.node.id === mediaId
      );
      mediaFound &&
        (mediaFound.node.mediaContentType === "IMAGE" ||
          mediaFound.node.mediaContentType === "VIDEO") &&
        mediaCollection.push({
          originalSource:
            mediaFound.node.mediaContentType === "IMAGE"
              ? mediaFound.node.image.url
              : mediaFound.node.originalSource.url,
          alt: mediaFound.node.alt,
          mediaContentType: mediaFound.node.mediaContentType,
        });
    }
    addMedia(destinationStore.subdomain, destinationProductId, mediaCollection);
  };

  const submitButton = (
    <button
      type="submit"
      className={`ml-auto flex items-center whitespace-nowrap rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-20 ${
        !destinationProductId && "hidden"
      }`}
      {...(isAddingMedia && { disabled: true })}
    >
      {isAddingMedia && <RefreshIcon className="mr-2 h-4 w-4 animate-spin" />}
      <span>Migrate data</span>
    </button>
  );

  useEffect(() => {
    if (!storesLoading && !storesError && stores?.length > 0) {
      setSourceStore(stores[0]);
      setDestinationStore(stores[1]);
    }
  }, [stores, storesError, storesLoading]);

  return (
    <div className="container mx-auto max-w-7xl">
      <Alert type="error" className="mb-4 py-4">
        <Heading
          color="text-red-800"
          level={3}
          leading="leading-5"
          className="mb-4"
        >
          This page / function is on hold.
        </Heading>
        <div className="space-y-2">
          <p>
            The purpose of this page / function is to facilitate the migration
            of media but there are some issues with migrating videos that are
            already hosted on Shopify CDN. <br />
            In order to continue the development, a ticket has to be opened with
            support and follow up with that.
          </p>
          <p className="font-bold">
            At the moment the system can successfully migrate images only.
          </p>
          <p>
            The function to delete migrated media is almost there but not
            completed.
          </p>
          <p>
            Possible future features could be (based on current available APIs):
            <ul className="list-inside list-disc">
              <li>productCreateMedia</li>
              <li>productDeleteMedia</li>
              <li>
                productReorderMedia (this is good tool:
                https://github.com/hello-pangea/dnd)
              </li>
              <li>
                productUpdateMedia (can update only the preview or alt text)
              </li>
              <li>productVariantAppendMedia</li>
              <li>productVariantDetachMedia</li>
            </ul>
          </p>
        </div>
      </Alert>
      <p className="mb-2 text-xs text-pink-900">
        for testing purposes: "Skull Sunflower" contains animated gif, "T1034"
        contains a video. Use: SZ10661A for testing AU / CA.
      </p>
      <div className="grid grid-cols-2 gap-2">
        {storesLoading ? (
          <div className="flex items-center gap-x-1">
            <Loading />
            <span className="text-sm text-indigo-600">Loading stores ...</span>
          </div>
        ) : storesError ? (
          <p className="text-sm font-semibold text-red-600">
            Error: ${storesError.message}
          </p>
        ) : (
          stores?.length > 0 &&
          sourceStore && (
            <>
              <form onSubmit={handleMigration} className="space-y-2 px-2">
                <Heading level={3}>Source:</Heading>
                <SearchShopify
                  selectedProductId={sourceProductId}
                  setSelectedProductId={setSourceProductId}
                  stores={stores}
                  selectedStore={sourceStore}
                  setSelectedStore={setSourceStore}
                />
                {sourceProductId && (
                  <SourceProductData
                    productId={sourceProductId}
                    store={sourceStore}
                    setProductMedia={setSourceProductMedia}
                    submitButton={submitButton}
                  />
                )}
              </form>

              {sourceProductId && (
                <div className="space-y-2 px-2">
                  <Heading level={3}>Destination:</Heading>
                  <SearchShopify
                    selectedProductId={destinationProductId}
                    setSelectedProductId={setDestinationProductId}
                    stores={stores}
                    selectedStore={destinationStore}
                    setSelectedStore={setDestinationStore}
                  />
                  {errorAddMedia && (
                    <p className="py-1 text-sm font-semibold text-red-500">
                      {errorAddMedia}
                    </p>
                  )}
                  {destinationProductId && (
                    <DestinationProductData
                      productId={destinationProductId}
                      store={destinationStore}
                      forceRerender={addMediaResponse}
                    />
                  )}
                </div>
              )}
            </>
          )
        )}
      </div>
    </div>
  );
}
