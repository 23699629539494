import axios from "axios";
import useSWR from "swr";
import { useState } from "react";

const fetcher = async (url) => {
  const response = await axios.get(url);
  return response.data;
};

export default function ShippingChannels() {
  const {
    data: shippingChannels,
    error: shippingChannelsError,
    mutate,
  } = useSWR("/api/shipping_channels", fetcher);

  const {
    data: shippingChannelTranslations,
    error: shippingChannelTranslationsError,
  } = useSWR("/api/shipping_channel_translations", fetcher);

  const [formState, setFormState] = useState({
    chinese_name: "",
    shipping_channel_translation_id: "",
  });

  if (shippingChannelsError | shippingChannelTranslationsError)
    return <div>failed to load</div>;
  if (!(shippingChannels && shippingChannelTranslations))
    return <div>loading...</div>;

  return (
    <div className="container mx-auto max-w-7xl">
      <h1>Shipping Channel Translations</h1>
      <table>
        <thead>
          <tr>
            <th>Chinese</th>
            <th>English</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input
                type="text"
                onChange={(e) => {
                  setFormState((formState) => ({
                    ...formState,
                    chinese_name: e.target.value,
                  }));
                }}
                value={formState.chinese_name}
              />
            </td>
            <td>
              <select
                name="shipping_channel_translation_id"
                onChange={(e) => {
                  setFormState((formState) => ({
                    ...formState,
                    shipping_channel_translation_id: e.target.value,
                  }));
                }}
                value={formState.shipping_channel_translation_id}
              >
                {/* empty option as default */}
                <option value=""></option>
                {shippingChannelTranslations.map((translation) => (
                  <option key={translation.id} value={translation.id}>
                    {translation.english_name}
                  </option>
                ))}
              </select>
            </td>
            <td>
              <button
                type="button"
                onClick={async (e) => {
                  await axios.post("/api/shipping_channels", formState);
                  await mutate();
                  setFormState({
                    chinese_name: "",
                    shipping_channel_translation_id: "",
                  });
                }}
              >
                add
              </button>
            </td>
          </tr>
          {shippingChannels.map((shippingChannel) => (
            <tr key={shippingChannel.id}>
              <td>{shippingChannel.chinese_name}</td>
              <td>
                {shippingChannel.shipping_channel_translation.english_name}
              </td>
              <td>
                <button
                  type="button"
                  onClick={async (e) => {
                    await axios.delete(
                      `/api/shipping_channels/${shippingChannel.id}`
                    );
                    mutate();
                  }}
                >
                  delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
