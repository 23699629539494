import React from "react";
import { Fragment } from "react/cjs/react.production.min";
import useOdaniWarehouseQuantity from "../hooks/useOdaniWarehouseQuantity";
import useProductExportCache from "../hooks/useProductExportCache";
import useShippedFromOdani from "../hooks/useShippedFromOdani";
import StockLevelOdaniTd from "./stock-level-odani-td";
import useComboSku from "../hooks/useComboSku";
import useComboSkuOdaniWarehouseQuantity from "../hooks/useComboSkuOdaniWarehouseQuantity";

export default function ({ sku }) {
  const { data: comboSku } = useComboSku(sku);
  const { data: productExportCache } = useProductExportCache(sku);
  const { data: odaniWarehouseQuantity } = useOdaniWarehouseQuantity(sku);
  const { data: comboSkuOdaniWarehouseQuantity } = useComboSkuOdaniWarehouseQuantity(sku);

  if ((!productExportCache || !odaniWarehouseQuantity) && (!comboSku || !comboSkuOdaniWarehouseQuantity)) {
    return null;
  }

  const sumWarehouseAttribute = (attribute) =>
    productExportCache.data["仓库信息"]
      .map((warehouse) => warehouse[attribute])
      .map(parseInt)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const sumWarehouseAttributeForComboSku = (attribute) => {
    const skuAttributeList = comboSku?.stock_export_caches?.map(product => {
      const sumOfAttribute = product.data["仓库信息"]
        .map((warehouse) => warehouse[attribute])
        .map(parseInt)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      return Math.floor(sumOfAttribute / product.pivot.quantity)
    });
    return Math.min(...skuAttributeList);
  }

  const days = [7, 28, 42];
  const warehouses = ["us-east", "us-west"];

  if (comboSku) {
    return (
      <tr>
        <td>{sku}</td>
        <td>{sumWarehouseAttributeForComboSku("仓库库存量")}</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>

        <td>{comboSkuOdaniWarehouseQuantity["美东仓库"]}</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>

        <td>{comboSkuOdaniWarehouseQuantity["美西仓库"]}</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
      </tr>
    );
  } else {
    return (
      <tr>
        <td>{sku}</td>
        <td>{sumWarehouseAttribute("仓库库存量")}</td>
        <td>{sumWarehouseAttribute("仓库7天总销量")}</td>
        <td>{sumWarehouseAttribute("仓库28天总销量")}</td>
        <td>{sumWarehouseAttribute("仓库42天总销量")}</td>

        <td>{odaniWarehouseQuantity["美东仓库"]}</td>
        {days.map((day) => (
          <StockLevelOdaniTd
            sku={sku}
            warehouse={warehouses[0]}
            days={day}
            key={day}
          />
        ))}
        <td>{odaniWarehouseQuantity["美西仓库"]}</td>
        {days.map((day) => (
          <StockLevelOdaniTd
            sku={sku}
            warehouse={warehouses[1]}
            days={day}
            key={day}
          />
        ))}
      </tr>
    );
  }
}
