import useSWR from "swr";
import { statefulRestfulFetcher } from "../utils/fetchers";

export default function useShippedFromOdani(sku, warehouse, days) {
  const { data, error } = useSWR(
    sku && [
      `/api/stock-export-caches/${sku}/shipped-from-odani`,
      {
        warehouse,
        days,
      },
    ],
    statefulRestfulFetcher
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
}
