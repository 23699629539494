import { SearchIcon } from "@heroicons/react/outline";

export default function DestinationMediaElement({
  media,
  reload,
  isChecked,
  handleCheckbox,
}) {
  return (
    <div className="relative flex divide-x rounded-md border bg-white">
      {media.node.status === "READY" ? (
        <>
          <button
            data-fancybox="destination-gallery"
            data-src={
              media.node.mediaContentType === "IMAGE"
                ? media.node.image.url
                : media.node.mediaContentType === "VIDEO"
                ? media.node.originalSource.url
                : null
            }
            className="group relative h-full max-h-[4.5rem] w-full max-w-[4.5rem] shrink-0 overflow-hidden rounded-l-md"
          >
            <img
              className="h-full max-h-[4.5rem] w-full max-w-[4.5rem] rounded-l-md object-cover object-center transition duration-300 ease-in-out group-hover:scale-125"
              src={media.node.preview.image.url}
              alt={media.node.preview.image.altText}
            />
            <div className="absolute inset-0 bg-indigo-900 opacity-25 transition duration-300 ease-in-out group-hover:opacity-0"></div>
            <SearchIcon className="absolute inset-1/2 h-6 w-6 -translate-x-1/2 -translate-y-1/2 stroke-gray-100 text-indigo-600 transition duration-300 ease-in-out group-hover:opacity-0" />
          </button>
          <div className="grow p-1">
            <input
              id={media.node.id}
              name={media.node.id}
              type="checkbox"
              className="absolute -right-1 -bottom-1 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              checked={isChecked.includes(media.node.id)}
              onChange={(e) => handleCheckbox(e)}
            />
            <label htmlFor={media.node.id} className="block space-x-1 text-xs">
              {media.node.mediaContentType === "IMAGE" ? (
                <>
                  <span className="font-medium text-indigo-900">alt text:</span>
                  <span>
                    {media.node.preview.image.altText ? (
                      media.node.preview.image.altText
                    ) : (
                      <span className="italic text-gray-500">empty</span>
                    )}
                  </span>
                </>
              ) : (
                <span>
                  No alt text available for this type of media:{" "}
                  <span className="font-medium text-indigo-900">
                    {media.node.mediaContentType}
                  </span>
                  .
                </span>
              )}
            </label>
          </div>
        </>
      ) : (
        <div className="flex grow items-end gap-x-1 p-1 text-xs">
          <div>
            <p>
              The media is still not ready to be displayed. Its status is:{" "}
              <span className="font-medium text-indigo-900">
                {media.node.status}
              </span>
              . Try to reload in few seconds.
            </p>
          </div>
          <button
            type="button"
            className="inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={() => reload()}
          >
            Reload
          </button>
        </div>
      )}
    </div>
  );
}
