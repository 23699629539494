import { gql } from "graphql-request";
import { skuReviewFormQuery } from "../graphql-queries";
import useGraphqlQuery from "../hooks/useGraphqlQuery";
import Loading from "./Loading";
import SkuReviewRow from "./sku-review-row";

export default function ({ productExportCache, store }) {
  const query = productExportCache && skuReviewFormQuery;
  const variables = {
    query: `sku:${productExportCache.sku}`,
  };

  const { data, isLoading, isError } = useGraphqlQuery(
    store?.subdomain,
    undefined,
    query,
    variables
  );
  return (
    <>
      <tr className="border-t border-gray-200">
        <th
          colSpan={25}
          scope="colgroup"
          className="sticky top-20 z-10 bg-gray-100 px-3 py-1 text-left text-xs font-semibold text-indigo-900"
        >
          {store?.subdomain}
        </th>
      </tr>
      {isLoading ? (
        <Loading />
      ) : (
        data?.products?.nodes?.map((product) =>
          product.variants.nodes.map((variant) => (
            <SkuReviewRow
              key={variant.id}
              product={product}
              variant={variant}
              store={store}
            />
          ))
        )
      )}
    </>
  );
}
