import axios from "axios";
import { useState, useEffect } from "react";

export default function useStores() {
  const [stores, setStores] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setError(null);
      setIsLoading(true);
      await axios
        .get("/api/stores")
        .then(function (response) {
          setStores(response.data);
        })
        .catch(function (error) {
          setError(error);
        })
        .finally(function () {
          setIsLoading(false);
        });
    };
    fetchData();
  }, []);

  return [stores, isLoading, error];
}
