import { ArrowSmRightIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { Disclosure } from "@headlessui/react";
import { useContext } from "react";
import { MOContext } from "../pages/multistore-operations";
import {
  convertToUsd,
  margin,
  shippingCosts,
  totalCosts,
  totalCostsinUsd,
} from "../utils/helpers";

export default function MarginRoi({ store, product, variant, currencyCode }) {
  const { mabangData, roiData, exchangeRates } = useContext(MOContext);

  return (
    <Disclosure as="div">
      {({ open }) => (
        <>
          <div className="mt-2 flex w-full items-start justify-between py-1 text-sm">
            <div
              className={`${
                open ? "hidden" : ""
              } relative rounded-md border border-gray-300 px-3 py-2 text-left`}
            >
              <p className="absolute -top-2 left-2 -mt-px inline-block bg-gray-50 px-1 text-xs font-medium text-gray-500">
                with / without battery [V/6000]
              </p>
              <div className="flex items-center gap-x-1">
                <div className="space-x-1">
                  <span className="font-semibold text-indigo-900">Margin:</span>
                  <span className="space-x-1 text-gray-900">
                    <span>
                      {margin(
                        roiData[
                          `${store.subdomain}_-_${product.node?.id}_-_${variant.node?.id}_-_price`
                        ],
                        store.transaction_costs_percentage,
                        totalCostsinUsd(
                          mabangData[variant.node?.sku],
                          store.shipping_rate_with_battery,
                          roiData[`${variant.node?.sku}_-_purchasePrice`],
                          exchangeRates
                        ),
                        currencyCode,
                        exchangeRates
                      ).toFixed(4)}
                    </span>
                    <span>/</span>
                    <span>
                      {margin(
                        roiData[
                          `${store.subdomain}_-_${product.node?.id}_-_${variant.node?.id}_-_price`
                        ],
                        store.transaction_costs_percentage,
                        totalCostsinUsd(
                          mabangData[variant.node?.sku],
                          store.shipping_rate_without_battery,
                          roiData[`${variant.node?.sku}_-_purchasePrice`],
                          exchangeRates
                        ),
                        currencyCode,
                        exchangeRates
                      ).toFixed(4)}
                    </span>
                    <span>%</span>
                  </span>
                </div>
                -
                <div className="space-x-1">
                  <span className="font-semibold text-indigo-900">ROI:</span>
                  <span className="space-x-1 text-gray-900">
                    <span>
                      {(
                        (1 /
                          margin(
                            roiData[
                              `${store.subdomain}_-_${product.node?.id}_-_${variant.node?.id}_-_price`
                            ],
                            store.transaction_costs_percentage,
                            totalCostsinUsd(
                              mabangData[variant.node?.sku],
                              store.shipping_rate_with_battery,
                              roiData[`${variant.node?.sku}_-_purchasePrice`],
                              exchangeRates
                            ),
                            currencyCode,
                            exchangeRates
                          )) *
                        100
                      ).toFixed(4)}
                    </span>
                    <span>/</span>
                    <span>
                      {(
                        (1 /
                          margin(
                            roiData[
                              `${store.subdomain}_-_${product.node?.id}_-_${variant.node?.id}_-_price`
                            ],
                            store.transaction_costs_percentage,
                            totalCostsinUsd(
                              mabangData[variant.node?.sku],
                              store.shipping_rate_without_battery,
                              roiData[`${variant.node?.sku}_-_purchasePrice`],
                              exchangeRates
                            ),
                            currencyCode,
                            exchangeRates
                          )) *
                        100
                      ).toFixed(4)}
                    </span>
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-x-1">
                <div className="space-x-1">
                  [
                  <span className="font-semibold text-indigo-900">Margin:</span>
                  <span className="space-x-1 text-gray-900">
                    <span>
                      {margin(
                        roiData[
                          `${store.subdomain}_-_${product.node?.id}_-_${variant.node?.id}_-_price`
                        ],
                        store.transaction_costs_percentage,
                        totalCostsinUsd(
                          mabangData[variant.node?.sku],
                          store.shipping_rate_with_battery,
                          roiData[`${variant.node?.sku}_-_purchasePrice`],
                          exchangeRates,
                          6000
                        ),
                        currencyCode,
                        exchangeRates
                      ).toFixed(4)}
                    </span>
                    <span>/</span>
                    <span>
                      {margin(
                        roiData[
                          `${store.subdomain}_-_${product.node?.id}_-_${variant.node?.id}_-_price`
                        ],
                        store.transaction_costs_percentage,
                        totalCostsinUsd(
                          mabangData[variant.node?.sku],
                          store.shipping_rate_without_battery,
                          roiData[`${variant.node?.sku}_-_purchasePrice`],
                          exchangeRates,
                          6000
                        ),
                        currencyCode,
                        exchangeRates
                      ).toFixed(4)}
                    </span>
                    <span>%</span>
                  </span>
                </div>
                -
                <div className="space-x-1">
                  <span className="font-semibold text-indigo-900">ROI:</span>
                  <span className="space-x-1 text-gray-900">
                    <span>
                      {(
                        (1 /
                          margin(
                            roiData[
                              `${store.subdomain}_-_${product.node?.id}_-_${variant.node?.id}_-_price`
                            ],
                            store.transaction_costs_percentage,
                            totalCostsinUsd(
                              mabangData[variant.node?.sku],
                              store.shipping_rate_with_battery,
                              roiData[`${variant.node?.sku}_-_purchasePrice`],
                              exchangeRates,
                              6000
                            ),
                            currencyCode,
                            exchangeRates
                          )) *
                        100
                      ).toFixed(4)}
                    </span>
                    <span>/</span>
                    <span>
                      {(
                        (1 /
                          margin(
                            roiData[
                              `${store.subdomain}_-_${product.node?.id}_-_${variant.node?.id}_-_price`
                            ],
                            store.transaction_costs_percentage,
                            totalCostsinUsd(
                              mabangData[variant.node?.sku],
                              store.shipping_rate_without_battery,
                              roiData[`${variant.node?.sku}_-_purchasePrice`],
                              exchangeRates,
                              6000
                            ),
                            currencyCode,
                            exchangeRates
                          )) *
                        100
                      ).toFixed(4)}
                    </span>
                  </span>
                  ]
                </div>
              </div>
            </div>
            <Disclosure.Button className="ml-auto mt-1 inline-flex items-end gap-x-1 px-2.5 py-1.5 text-xs font-medium text-indigo-600">
              {`${open ? "hide" : "show"}`} details
              <ChevronRightIcon className="h-4 w-4" />
            </Disclosure.Button>
          </div>
          <Disclosure.Panel as="div">
            <div className="space-x-1">
              <span className="text-gray-500">
                Shipping Rate (
                <span className="font-semibold">with / without battery</span>
                ):
              </span>
              <span className="space-x-1 text-gray-900">
                <span>CNY</span>
                <span>{store.shipping_rate_with_battery.toFixed(2)}</span>
                <span>/</span>
                <span>{store.shipping_rate_without_battery.toFixed(2)}</span>
              </span>
            </div>
            <div className="space-x-1">
              <span className="text-gray-500">
                Shipping Costs = [(Product Weight (in kg) * Shipping Rate (in
                CNY))] =
              </span>
              <span className="space-x-1 text-gray-900">
                <span>CNY</span>
                <span>
                  {shippingCosts(
                    mabangData[variant.node?.sku],
                    store.shipping_rate_with_battery
                  ).toFixed(4)}
                </span>
                <span>/</span>
                <span>
                  {shippingCosts(
                    mabangData[variant.node?.sku],
                    store.shipping_rate_without_battery
                  ).toFixed(4)}
                </span>
              </span>
            </div>
            <div className="space-x-1">
              <span className="text-gray-500">
                Total Costs = Shipping Costs (ex. fixed fees) + Product Costs =
              </span>
              <span className="flex items-center gap-x-1 text-gray-900">
                <span>CNY</span>
                <span>
                  {totalCosts(
                    mabangData[variant.node?.sku],
                    store.shipping_rate_with_battery,
                    roiData[`${variant.node?.sku}_-_purchasePrice`]
                  ).toFixed(4)}
                </span>
                <span>/</span>
                <span>
                  {totalCosts(
                    mabangData[variant.node?.sku],
                    store.shipping_rate_without_battery,
                    roiData[`${variant.node?.sku}_-_purchasePrice`]
                  ).toFixed(4)}
                </span>
                <ArrowSmRightIcon className="h-4 w-4" />
                <span>USD</span>
                <span>
                  {totalCostsinUsd(
                    mabangData[variant.node?.sku],
                    store.shipping_rate_with_battery,
                    roiData[`${variant.node?.sku}_-_purchasePrice`],
                    exchangeRates
                  ).toFixed(4)}
                </span>
                <span>/</span>
                <span>
                  {totalCostsinUsd(
                    mabangData[variant.node?.sku],
                    store.shipping_rate_without_battery,
                    roiData[`${variant.node?.sku}_-_purchasePrice`],
                    exchangeRates
                  ).toFixed(4)}
                </span>
              </span>
            </div>
            <div className="space-x-1">
              <span className="text-gray-500">Transaction Costs:</span>
              <span className="text-gray-900">
                {store.transaction_costs_percentage.toFixed(2)}%
              </span>
            </div>
            {currencyCode !== "USD" ? (
              <div className="space-x-1">
                <span className="space-x-1 text-gray-500">
                  <span>Selling Price (in USD) +</span>
                  <span>
                    {exchangeRates[`${currencyCode}_spread`]}% spread :
                  </span>
                </span>
                <span className="space-x-1 text-gray-900">
                  <span>USD</span>
                  <span>
                    {convertToUsd(
                      roiData[
                        `${store.subdomain}_-_${product.node?.id}_-_${variant.node?.id}_-_price`
                      ],
                      currencyCode,
                      exchangeRates
                    ).toFixed(4)}
                  </span>
                </span>
              </div>
            ) : null}
            <div className="space-x-1">
              <span className="text-gray-500">
                <span className="font-semibold text-indigo-900">Margin</span> =
                [(Selling Price (in USD) * (1-Transaction Costs)) - Total Costs]
                / (Selling Price (in USD) * (100-Transaction Costs)) * 100 =
              </span>
              <span className="space-x-1 text-gray-900">
                <span>
                  {margin(
                    roiData[
                      `${store.subdomain}_-_${product.node?.id}_-_${variant.node?.id}_-_price`
                    ],
                    store.transaction_costs_percentage,
                    totalCostsinUsd(
                      mabangData[variant.node?.sku],
                      store.shipping_rate_with_battery,
                      roiData[`${variant.node?.sku}_-_purchasePrice`],
                      exchangeRates
                    ),
                    currencyCode,
                    exchangeRates
                  ).toFixed(4)}
                </span>
                <span>/</span>
                <span>
                  {margin(
                    roiData[
                      `${store.subdomain}_-_${product.node?.id}_-_${variant.node?.id}_-_price`
                    ],
                    store.transaction_costs_percentage,
                    totalCostsinUsd(
                      mabangData[variant.node?.sku],
                      store.shipping_rate_without_battery,
                      roiData[`${variant.node?.sku}_-_purchasePrice`],
                      exchangeRates
                    ),
                    currencyCode,
                    exchangeRates
                  ).toFixed(4)}
                </span>
                <span>%</span>
              </span>
            </div>
            <div className="space-x-1">
              <span className="text-gray-500">
                <span className="font-semibold text-indigo-900">ROI</span> =
                Custom Label 1 = 1/Margin =
              </span>
              <span className="space-x-1 text-gray-900">
                <span>
                  {(
                    (1 /
                      margin(
                        roiData[
                          `${store.subdomain}_-_${product.node?.id}_-_${variant.node?.id}_-_price`
                        ],
                        store.transaction_costs_percentage,
                        totalCostsinUsd(
                          mabangData[variant.node?.sku],
                          store.shipping_rate_with_battery,
                          roiData[`${variant.node?.sku}_-_purchasePrice`],
                          exchangeRates
                        ),
                        currencyCode,
                        exchangeRates
                      )) *
                    100
                  ).toFixed(4)}
                </span>
                <span>/</span>
                <span>
                  {(
                    (1 /
                      margin(
                        roiData[
                          `${store.subdomain}_-_${product.node?.id}_-_${variant.node?.id}_-_price`
                        ],
                        store.transaction_costs_percentage,
                        totalCostsinUsd(
                          mabangData[variant.node?.sku],
                          store.shipping_rate_without_battery,
                          roiData[`${variant.node?.sku}_-_purchasePrice`],
                          exchangeRates
                        ),
                        currencyCode,
                        exchangeRates
                      )) *
                    100
                  ).toFixed(4)}
                </span>
              </span>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
