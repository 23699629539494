import axios from "axios";
import { useCallback, useEffect, useState } from "react";

export default function useShopifyReport(subdomain, sku) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [report, setReport] = useState(null);

  const fetchReport = useCallback(async () => {
    setError(null);
    setIsLoading(true);
    await axios
      .post(`/api/graphql/${subdomain}/unstable`, {
        query: `query {
            ${[7, 30, 45].map(
              (days) => `last_${days}_days: shopifyqlQuery(
              query: "FROM sales SHOW net_quantity WHERE variant_sku = '${sku}' SINCE -${days}d UNTIL -1d"
            ) {
              ... on TableResponse {
                tableData {
                  rowData
                }
              }
              parseErrors {
                code
                message
              }
            }`
            )}
          }`,
      })
      .then(function (response) {
        if (response.data?.errors) {
          setError(
            response.data?.errors.map((error) => error.message).join(", ")
          );
        } else {
          setReport(response.data.data);
        }
      })
      .catch(function (error) {
        setError(error);
      })
      .finally(function () {
        setIsLoading(false);
      });
  }, [sku, subdomain]);

  useEffect(() => {
    fetchReport();
  }, [fetchReport]);

  return [isLoading, error, report, fetchReport];
}
