import { Switch, Route } from "react-router-dom";
import Home from "./pages/home";
import Login from "./pages/login";
import Register from "./pages/register";
import ProtectedRoute from "./components/protected-route";
import Exports from "./pages/exports";
import NotUploadedShipments from "./pages/not-uploaded-shipments";
import ShippingChannels from "./pages/shipping-channels";
import ManualOrderExport from "./pages/manual-order-export";
import Navbar from "./components/Navbar";
import Container from "./components/Container";
import MultistoreOperations from "./pages/multistore-operations";
import StoresManager from "./pages/manage-inputs";
import ProductDataMigration from "./pages/product-data-migration";
import ManageOdaniShippingFiles from "./pages/ManageOdaniShippingFiles";
import MultistoreOperationsPro from "./pages/multistore-operations-pro";
import CreateOrUpdateShopifyCollection from "./pages/create-or-update-shopify-collection";
import BulkUpdateSkuPrice from "./pages/bulk-update-sku-price";

function App() {
  return (
    <>
      <Navbar />
      <Container>
        {/* The following will render based on current path. */}
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/register">
            <Register />
          </Route>
          <ProtectedRoute path="/exports">
            <Exports />
          </ProtectedRoute>
          <ProtectedRoute path="/not-uploaded-shipments">
            <NotUploadedShipments />
          </ProtectedRoute>
          <ProtectedRoute path="/shipping-channels">
            <ShippingChannels />
          </ProtectedRoute>
          <ProtectedRoute path="/manual-order-export">
            <ManualOrderExport />
          </ProtectedRoute>
          <ProtectedRoute path="/multistore-operations">
            <MultistoreOperations />
          </ProtectedRoute>
          <ProtectedRoute path="/multistore-operations-pro">
            <MultistoreOperationsPro />
          </ProtectedRoute>
          <ProtectedRoute path="/manage-inputs">
            <StoresManager />
          </ProtectedRoute>
          <ProtectedRoute path="/product-data-migration">
            <ProductDataMigration />
          </ProtectedRoute>
          <ProtectedRoute path="/manage-odani-shipping-files">
            <ManageOdaniShippingFiles />
          </ProtectedRoute>
          <ProtectedRoute path="/create-or-update-shopify-collection">
            <CreateOrUpdateShopifyCollection />
          </ProtectedRoute>
          <ProtectedRoute path="/bulk-update-sku-price">
            <BulkUpdateSkuPrice />
          </ProtectedRoute>
          <ProtectedRoute path="/">
            <Home />
          </ProtectedRoute>
        </Switch>
      </Container>
    </>
  );
}

export default App;
