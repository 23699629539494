import { Fragment, useEffect, useState } from "react";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DateTime } from "luxon";
import OrderShippingCost from "../components/export-forms/order-shipping-cost";
import ProductAvailabilities from "../components/export-forms/product-availabilities";
import Heading from "../components/Heading";

const axios = require("axios").default;

const Exports = () => {
  let [exports, setExports] = useState([]);

  let [modalOpened, setModalOpened] = useState(false);

  const [formState, setFormState] = useState({
    export: "Order Shipping Cost",
  });

  const [lastSubmitedExport, setLastSubmitedExport] = useState();

  useEffect(updateExports, [lastSubmitedExport]);

  useEffect(() => {
    const interval = setInterval(updateExports, 30000);
    return () => clearInterval(interval);
  }, []);

  function updateExports() {
    return axios
      .get("/api/user/exports")
      .then((response) => setExports(response.data))
      .then(() => console.log(exports));
  }

  const handleExportSubmit = async (e) => {
    const apiParams =
      formState.export == "Order Shipping Cost"
        ? {
            ...formState,
            params: {
              createdAtBegin: DateTime.fromJSDate(
                formState.params.createdAtBegin
              )
                .set({ hour: 0, minute: 0, second: 0 })
                .setZone("America/Los_Angeles", { keepLocalTime: true })
                .toISO(),
              createdAtEnd: DateTime.fromJSDate(formState.params.createdAtEnd)
                .set({ hour: 23, minute: 59, second: 59 })
                .setZone("America/Los_Angeles", { keepLocalTime: true })
                .toISO(),
            },
          }
        : formState;

    const res = await axios.post("/api/user/exports", apiParams);
    setModalOpened(false);
    setLastSubmitedExport(res.data);
  };

  let Form;

  switch (formState.export) {
    case "Order Shipping Cost":
      Form = OrderShippingCost;
      break;
    case "Product Availabilities":
      Form = ProductAvailabilities;
      break;
  }

  return (
    <div className="container mx-auto max-w-7xl">
      <Modal isOpen={modalOpened}>
        <button
          onClick={() => setModalOpened(false)}
          className="inline-flex items-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-sm font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Close
        </button>
        <div>
          <label htmlFor="export-job">Job:</label>
          <select
            name="exportJob"
            id="export-job"
            value={formState.export}
            onChange={(e) =>
              setFormState({
                export: e.target.value, // need to clear the state anyway so not spreading old values here.
              })
            }
          >
            <option value="Order Shipping Cost">Order Shipping Cost</option>
            <option value="Product Availabilities">
              Product Availabilities
            </option>
          </select>
        </div>
        <div>{<Form formState={formState} setFormState={setFormState} />}</div>
        <button
          type="button"
          onClick={handleExportSubmit}
          className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Submit
        </button>
      </Modal>
      <button
        onClick={() => setModalOpened(true)}
        className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Create Export
      </button>
      <Heading className={"mb-6"}>Exports</Heading>
      <div style={{ display: "grid", gridTemplateColumns: "repeat(5, 1fr)" }}>
        <div>Id</div>
        <div>Name</div>
        <div>Created At</div>
        <div>File</div>
        <div>Status</div>
        {exports
          .sort((prev, next) => next.id - prev.id)
          .map((exportItem) => (
            <Fragment key={exportItem.id}>
              <div>{exportItem.id}</div>
              <div>{exportItem.name}</div>
              <div>
                {DateTime.fromISO(exportItem.created_at).toLocaleString(
                  DateTime.DATETIME_FULL
                )}
              </div>
              <a
                href={`${process.env.REACT_APP_BACKEND_PROTOCAL}://${process.env.REACT_APP_BACKEND_DOMAIN}/api/user/exports/${exportItem.id}`}
                className="font-medium text-indigo-600 underline hover:text-indigo-500 hover:no-underline"
              >
                {exportItem.file_path}
              </a>
              <div>{exportItem.status}</div>
            </Fragment>
          ))}
      </div>
    </div>
  );
};

export default Exports;
