import Loading from "./Loading";
import DisplayShopifyData from "./DisplayShopifyData";
import useShopifyData from "../hooks/useShopifyData";
import { useContext, useEffect, useState } from "react";
import { MOContext } from "../pages/multistore-operations";
import useShopifyMutation from "../hooks/useShopifyMutation";
import Alert from "./Alert";
import { retructureFormDataForMutation } from "../utils/helpers";

export default function DataFromShopify({ store }) {
  const [minHeight, setMinHeight] = useState(0);
  const { selectedSku, shopifyData, mabangData } = useContext(MOContext);
  const [isLoading, error, fetchData] = useShopifyData({ selectedSku, store });
  const [
    isMutationLoading,
    mutationError,
    setMutationError,
    mutationResponse,
    setMutationResponse,
    sendMutation,
    isSaveHistoryLoading,
    saveHistoryError,
  ] = useShopifyMutation();

  function getLastSkuFromFirstProduct() {
    return shopifyData[store.subdomain]?.data?.products?.edges[0].node?.variants
      ?.edges[
      shopifyData[store.subdomain]?.data?.products?.edges[0].node?.variants
        ?.edges.length - 1
    ].node?.sku;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const dataForMutation = retructureFormDataForMutation(
      new FormData(event.currentTarget)
    );
    await sendMutation(store.subdomain, dataForMutation);
  };

  useEffect(() => {
    /** without this reset after new SKU selected
     * there would be a bug of too much space left
     * from previous SKU with many variants
     */
    setMinHeight(0);
  }, [selectedSku]);

  useEffect(() => {
    // on a successful mutation, refetch the data
    !isMutationLoading &&
      !isSaveHistoryLoading &&
      !mutationError &&
      !saveHistoryError &&
      mutationResponse &&
      mutationResponse?.data &&
      fetchData();
  }, [
    fetchData,
    isMutationLoading,
    isSaveHistoryLoading,
    mutationError,
    saveHistoryError,
    mutationResponse,
  ]);

  useEffect(() => {
    /** after the data is fetched,
     * set a min-height for the data container
     * so that it won't collapse on next refetch
     * and there's no need to scroll
     */
    const setMinHeightForDataContainer = () => {
      const element = document.getElementById(
        `${store.subdomain}_-_dataContainer`
      );
      if (element) {
        setMinHeight(element.getBoundingClientRect().height);
      }
    };
    !isLoading &&
      !error &&
      store.subdomain in shopifyData &&
      shopifyData[store.subdomain].data?.products?.edges.length > 0 &&
      setMinHeightForDataContainer();
  }, [error, isLoading, shopifyData, store.subdomain]);

  return (
    <form
      onSubmit={handleSubmit}
      className="-mx-4 divide-y border border-indigo-200/50 bg-white shadow sm:mx-0 sm:rounded-lg"
    >
      <div className="sticky top-0 z-30 bg-indigo-900 px-4 py-1 text-sm text-white sm:rounded-t-lg">
        <div className="sm:flex sm:flex-wrap sm:items-center">
          <div className="py-1 sm:flex sm:grow sm:items-center sm:gap-x-2">
            <p className="font-medium">Data from {store.alias} store</p>
            <button
              onClick={() => fetchData()}
              type="button"
              className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-indigo-600 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-20"
              {...(isLoading && { disabled: true })}
            >
              refetch data
            </button>
          </div>
          <div className="space-x-3 sm:flex sm:items-center">
            {(isMutationLoading || isSaveHistoryLoading) && (
              <Loading className="text-white" />
            )}
            {!isLoading &&
              !error &&
              store.subdomain in shopifyData &&
              !shopifyData[store.subdomain].errors &&
              shopifyData[store.subdomain].data?.products?.edges.length > 0 && (
                <button
                  type="submit"
                  className="flex items-center gap-x-2.5 rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-indigo-600 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-20"
                  {...((isMutationLoading || isSaveHistoryLoading) && {
                    disabled: true,
                  })}
                >
                  <span>Send data to {store.alias} store</span>
                </button>
              )}
          </div>
        </div>
        {mutationError || saveHistoryError ? (
          <Alert
            type="error"
            dismissable={true}
            onClick={() => setMutationError(null)}
          >
            Error: {mutationError} {saveHistoryError}
          </Alert>
        ) : (
          mutationResponse &&
          mutationResponse?.data && (
            <Alert
              type="success"
              dismissable={true}
              onClick={() => setMutationResponse(null)}
            >
              The data has been sent and saved successfully
            </Alert>
          )
        )}
      </div>
      <div
        id={`${store.subdomain}_-_dataContainer`}
        className="divide-y"
        style={{ minHeight: `${minHeight}px` }}
      >
        {isLoading ? (
          <div className="flex items-center gap-x-1 px-4 py-2 sm:px-6">
            <Loading />
            <span className="ml-2 text-sm text-indigo-600 sm:ml-3">
              Retriving data from {store.alias} Shopify store ...
            </span>
          </div>
        ) : error ? (
          <p className="px-4 py-2 text-sm font-semibold text-red-500 sm:px-6">
            Error: {error.message}
          </p>
        ) : store.subdomain in shopifyData ? (
          shopifyData[store.subdomain].errors ? (
            shopifyData[store.subdomain].errors.map((error, index) => (
              <p
                key={index}
                className="px-4 py-2 text-sm font-semibold text-red-500 sm:px-6"
              >
                Error: {error.message}
              </p>
            ))
          ) : shopifyData[store.subdomain].data?.products?.edges.length ===
            0 ? (
            <p className="px-4 py-2 text-sm font-semibold text-red-500 sm:px-6">
              No products found on this store for the selected SKU.
            </p>
          ) : (
            shopifyData[store.subdomain].data?.products?.edges.map(
              (product, index) => {
                /**
                Unfortunately Mabang cannot be queried in parallel so here is
                the solution to serialise the requests from different SKUs
                from different stores.
                - First product data can be displayed.
                - For the following products I need to check if the last variant
                  of the previous product has been fetched.
                - To do that I need to verify that the context mabangData variable has 
                  some data inside (i.e.: the actual SKU).
                */
                return index === 0 ? (
                  <DisplayShopifyData
                    key={product.node.id}
                    product={product}
                    currencyCode={
                      shopifyData[store.subdomain].data?.shop?.currencyCode
                    }
                    store={store}
                  />
                ) : mabangData[getLastSkuFromFirstProduct()] &&
                  mabangData[getLastSkuFromFirstProduct()].sku ? (
                  <DisplayShopifyData
                    key={product.node.id}
                    product={product}
                    currencyCode={
                      shopifyData[store.subdomain].data?.shop?.currencyCode
                    }
                    store={store}
                  />
                ) : null;
              }
            )
          )
        ) : null}
      </div>
    </form>
  );
}
