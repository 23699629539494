import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XIcon,
} from "@heroicons/react/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Alert({
  type = "info",
  className,
  children,
  dismissable = false,
  onClick,
}) {
  return (
    <div
      className={classNames(
        "my-2 shrink-0 rounded border py-1 px-2",
        type === "info" && "border-blue-200 bg-blue-50",
        type === "error" && "border-red-200 bg-red-50",
        type === "success" && "border-green-200 bg-green-50",
        className
      )}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          {type === "info" && (
            <InformationCircleIcon
              className="h-5 w-5 text-blue-400"
              aria-hidden="true"
            />
          )}
          {type === "error" && (
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-400"
              aria-hidden="true"
            />
          )}
          {type === "success" && (
            <CheckCircleIcon
              className="h-5 w-5 text-green-400"
              aria-hidden="true"
            />
          )}
        </div>
        <div
          className={classNames(
            "ml-2 grow text-sm font-medium",
            type === "info" && "text-blue-800",
            type === "error" && "text-red-800",
            type === "success" && "text-green-800"
          )}
        >
          {children}
        </div>
        {dismissable && (
          <button type="button" className="shrink-0" onClick={onClick}>
            <XIcon className="h-5 w-5 text-gray-500" />
          </button>
        )}
      </div>
    </div>
  );
}
