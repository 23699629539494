import useSWR from "swr";
import { statefulRestfulFetcher } from "../utils/fetchers";

export default function () {
  const { data, error, isValidating, mutate } = useSWR(
    "/api/settings/latest",
    statefulRestfulFetcher
  );

  return {
    data: data ? JSON.parse(data?.data?.[0]) : data,
    isLoading: !error && !data,
    error,
    isValidating,
    mutate,
  };
}
