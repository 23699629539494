import axios from "axios";
import { useState } from "react";

export default function useSaveHistory() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const saveHistory = async (sku, dataObject) => {
    setError(null);
    setIsLoading(true);
    await axios
      .post(`/api/stocks/${sku}/histories`, {
        shop_data: [JSON.stringify(dataObject)],
      })
      .catch(function (error) {
        setError(error.response);
      })
      .finally(function () {
        setIsLoading(false);
      });
  };

  return [isLoading, error, saveHistory];
}
