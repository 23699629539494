import axios from "axios";
import { useEffect, useState } from "react";

export default function NotUploadedShipments() {
  const [shipments, setShipments] = useState([]);

  const [shipmentDetailByShipmentId, setShipmentDetailByShipmentId] = useState(
    {}
  );

  const [checkedShipmentIds, setCheckedShipmentIds] = useState([]);

  const fetchShipments = async () => {
    const { data: shipments } = await axios
      .get("/api/not_uploaded_shipments")
      .then((res) => res.data);
    setShipments(shipments);
  };

  useEffect(() => {
    fetchShipments();
  }, []);

  useEffect(() => {
    (async () => {
      for (const shipment of shipments) {
        await axios
          .get(`/api/not_uploaded_shipments/${shipment.id}`)
          .then((res) => res.data)
          .then(({ details }) => {
            setShipmentDetailByShipmentId((shipmentDetailByShipmentId) => ({
              ...shipmentDetailByShipmentId,
              [shipment.id]: details,
            }));
          });
      }
    })();
  }, [shipments]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios.post("/api/not_uploaded_shipments/batch_delete", {
      ids: checkedShipmentIds,
    });
    await fetchShipments();
  };

  return (
    <div className="container mx-auto max-w-7xl">
      <h1>Not Uploaded Shipments</h1>
      <form onSubmit={handleSubmit}>
        <table>
          <thead>
            <tr>
              <th>Order Name</th>
              <th>Mabang ID</th>
              <th>Mabang SKUs</th>
              <th>Shipping Channel</th>
              <th>Tracking Number</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {shipments.map((shipment) => {
              const detail = shipmentDetailByShipmentId?.[shipment.id];
              return (
                <tr key={shipment.id}>
                  <td>{detail?.orderName}</td>
                  <td>{shipment.platform_order_id}</td>
                  <td>
                    <ul>
                      {detail?.items?.map((item) => (
                        <li key={item.sku}>
                          {item.sku}: {item.quantity}
                        </li>
                      ))}
                    </ul>
                  </td>
                  <td>{detail?.shippingChannel}</td>
                  <td>{detail?.trackingNumber}</td>
                  <td>
                    <input
                      type="checkbox"
                      checked={checkedShipmentIds.includes(shipment.id)}
                      onChange={(e) => {
                        setCheckedShipmentIds((oldIds) => {
                          return e.target.checked
                            ? [...oldIds, shipment.id]
                            : oldIds.filter((id) => id !== shipment.id);
                        });
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <button type="submit">Delete Selected</button>
      </form>
    </div>
  );
}
