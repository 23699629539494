import useSWR from "swr";
import { statefulRestfulFetcher } from "../utils/fetchers";

export default function useUnitCost(store, sku, currencyCode) {
  const { data, error } = useSWR(
    sku &&
      store && [
        `/api/stock-export-caches/${sku}/stores/${store.id}/unit-cost`,
        {
          currencyCode,
        },
      ],
    statefulRestfulFetcher
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
}
