import { useState, useEffect, useContext } from "react";
import { Combobox } from "@headlessui/react";
import axios from "axios";
import Loading from "./Loading";
import { MOContext } from "../pages/multistore-operations";
import useDebounce from "../hooks/useDebounce";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SearchSku() {
  const { selectedSku, setSelectedSku } = useContext(MOContext);
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 500);
  const [skus, setSkus] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setError(null);
      setIsLoading(true);
      await axios
        .get(`/api/stock-export-caches`, {
          params: {
            sku: debouncedQuery,
          },
        })
        .then(function (response) {
          setSkus(response.data.data);
        })
        .catch(function (error) {
          setError(error);
        })
        .then(function () {
          setIsLoading(false);
        });
    };

    debouncedQuery ? fetchData() : setSkus([]);
  }, [debouncedQuery]);

  return (
    <Combobox
      value={selectedSku}
      onChange={(sku) => {
        if (sku) {
          setSelectedSku(sku);
        }
      }}
      as="div"
      className="relative mx-auto max-w-sm"
    >
      <div className="relative">
        <Combobox.Input
          onChange={(event) => {
            setQuery(event.target.value);
          }}
          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 uppercase text-gray-900 shadow-sm placeholder:normal-case placeholder:text-gray-500 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
          placeholder="Type SKU..."
          displayValue={(selectedSku) => selectedSku.sku}
        />
        {isLoading && (
          <div className="absolute top-1/2 right-3 -translate-y-1/2">
            <Loading />
          </div>
        )}
      </div>
      {error && (
        <p className="p-4 pb-0 text-sm font-semibold text-red-500">
          Error: ${error.message}
        </p>
      )}
      {query && skus.length === 0 && !isLoading && !error && (
        <p className="p-4 pb-0 text-sm font-semibold text-red-500">
          No SKU found.
        </p>
      )}
      {query && skus.length > 0 && !isLoading && !error && (
        <Combobox.Options className="absolute z-10 mt-1 max-h-80 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {skus.map((sku) => (
            <Combobox.Option
              key={sku.id}
              value={sku}
              className={({ active }) =>
                classNames(
                  "relative cursor-default select-none py-2 pl-3 pr-9 uppercase",
                  active ? "bg-indigo-600 text-white" : "text-gray-900"
                )
              }
            >
              {sku.sku}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      )}
    </Combobox>
  );
}
