import axios from "axios";
import { GraphQLClient } from "graphql-request";
import { getCookieValue } from "./helpers";

export const statefulAxiosInstance = axios.create({
  withCredentials: true,
  baseURL: `${process.env.REACT_APP_BACKEND_PROTOCAL}://${process.env.REACT_APP_BACKEND_DOMAIN}`,
});
export async function statefulRestfulFetcher(arg) {
  let url, params;
  if (Array.isArray(arg)) {
    [url, params] = arg;
  } else {
    url = arg;
  }
  const response = await statefulAxiosInstance.get(url, {
    params,
  });
  return response.data;
}

export async function graphqlFetcher([
  subdomain,
  apiVersion = "2022-10",
  query,
  variables,
]) {
  const url = `${process.env.REACT_APP_BACKEND_PROTOCAL}://${process.env.REACT_APP_BACKEND_DOMAIN}/api/graphql/${subdomain}/${apiVersion}`;

  const graphQLClient = new GraphQLClient(url, {
    credentials: "include",
    mode: "cors",
    headers: {
      "X-XSRF-TOKEN": decodeURIComponent(getCookieValue("XSRF-TOKEN")),
    },
  });

  return await graphQLClient.request(query, variables);
}

const statelessInstance = axios.create({
  withCredentials: false,
});

export async function statelessRestfulFetcher(arg) {
  let url, params;
  if (Array.isArray(arg)) {
    [url, params] = arg;
  } else {
    url = arg;
  }
  const response = await statelessInstance.get(url, {
    params,
  });
  return response.data;
}
