import useSWR from "swr";
import { graphqlFetcher } from "../utils/fetchers";

export default function useGraphqlQuery(
  subdomain,
  apiVersion,
  query,
  variables
) {
  const { data, error } = useSWR(
    query && subdomain && [subdomain, apiVersion, query, variables],
    graphqlFetcher
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
}
