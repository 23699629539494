export const volumetricWeightInKg = (mabangData, coefficient = 8000) =>
  (parseFloat(mabangData.dimensions?.length) *
    parseFloat(mabangData.dimensions?.width) *
    parseFloat(mabangData.dimensions?.height)) /
  coefficient;

export const weightInKg = (mabangData) => parseFloat(mabangData.weight) / 1000;

export const volume = (mabangData) =>
  parseFloat(mabangData.dimensions?.length) *
  parseFloat(mabangData.dimensions?.width) *
  parseFloat(mabangData.dimensions?.height);

export const shippingCosts = (mabangData, shippingRate, coefficient = 8000) =>
  (weightInKg(mabangData) > volumetricWeightInKg(mabangData, coefficient)
    ? weightInKg(mabangData)
    : volumetricWeightInKg(mabangData, coefficient)) * shippingRate;

export const totalCosts = (
  mabangData,
  shippingRate,
  purchasePrice,
  coefficient = 8000
) =>
  shippingCosts(mabangData, shippingRate, coefficient) +
  parseFloat(purchasePrice);

export const totalCostsinUsd = (
  mabangData,
  shippingRate,
  purchasePrice,
  exchangeRates,
  coefficient = 8000
) =>
  (totalCosts(mabangData, shippingRate, purchasePrice, coefficient) * 1) /
  parseFloat(exchangeRates.CNY_manual);

export const margin = (
  price,
  transactionCosts,
  totalCostsinUsd,
  currencyCode,
  exchangeRates
) =>
  ((convertToUsd(parseFloat(price), currencyCode, exchangeRates) *
    (1 - parseFloat(transactionCosts) / 100) -
    totalCostsinUsd) /
    (convertToUsd(parseFloat(price), currencyCode, exchangeRates) *
      (1 - parseFloat(transactionCosts) / 100))) *
  100;

export const convertToUsd = (value, from, exchangeRates) =>
  (parseFloat(value) / parseFloat(exchangeRates[from])) *
  ((100 + parseFloat(exchangeRates[`${from}_spread`])) / 100);

export const retructureFormDataForMutation = (formData) => {
  let products = [];
  for (let [key, value] of formData.entries()) {
    if (key.includes("purchasePrice")) continue;
    const productId = key.split("_-_")[0];

    // if the product is not there already then I add it
    if (!products.some(({ id }) => id === productId)) {
      products.push({
        id: productId,
        title: "",
        existingMetafields: [],
        newMetafields: [],
        variants: [],
      });
    }

    // now that I'm sure it's there, I get the index so I can add related Metafields
    const productIndex = products.findIndex(({ id }) => id === productId);

    if (key.split("_-_")[1].includes("title")) {
      products[productIndex].title = value;
    }
    if (key.split("_-_")[1].includes("shopify/Metafield")) {
      products[productIndex].existingMetafields.push({
        id: key.split("_-_")[1],
        value: value,
      });
    }
    if (key.split("_-_")[1].includes("custom_label_")) {
      products[productIndex].newMetafields.push({
        key: key.split("_-_")[1],
        value: value,
      });
    }
    if (key.split("_-_")[1].includes("shopify/ProductVariant")) {
      const variantId = key.split("_-_")[1];

      // if the variant is not there already then I add it
      if (!products[productIndex].variants.some(({ id }) => id === variantId)) {
        products[productIndex].variants.push({
          id: variantId,
        });
      }

      const variantIndex = products[productIndex].variants.findIndex(
        ({ id }) => id === variantId
      );
      if (key.split("_-_")[2].includes("price")) {
        products[productIndex].variants[variantIndex].price = value;
      }
      if (key.split("_-_")[2].includes("compareAtPrice")) {
        products[productIndex].variants[variantIndex].compareAtPrice = value;
      }
    }
  }
  return products;
};

// https://stackoverflow.com/a/25490531
export const getCookieValue = (name) =>
  document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";

export function calculateDiscount(price, compareAtPrice) {
  return ((compareAtPrice - price) / compareAtPrice) * 100;
}
