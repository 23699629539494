import { Tab } from "@headlessui/react";
import { DateTime } from "luxon";
import { createContext, useState } from "react";
import DateRangeInput from "./date-range-input";
import ProductLevelForm from "./product-level-form";
import { DownloadIcon } from "@heroicons/react/outline";
import axios from "axios";

export const ReportDateRangeContext = createContext(null);

const productTypes = [
  { value: '', name: 'All products' },
  { value: 'created_at', name: 'Products created last month' },
  { value: 'updated_at', name: 'Products updated last month' },
];

export default function ({ productExportCache }) {
  const [dates, setDates] = useState({
    from: DateTime.now().minus({
      month: 1,
      day: -1,
    }),
    to: DateTime.now(),
  });

  const [selectedType, setSelectedType] = useState('');
  const exportProductLevelTab = async (startDate, endDate, type) => {
    try {
      const response = await axios.post("/api/product_level_tab_exports", {
        type,
        startDate,
        endDate,
      });
      alert(JSON.stringify(response.data));
    } catch (error) {
      alert(JSON.stringify(error?.response?.data));
    }
  }

  return (
    <Tab.Panel>
      <div
        className="mr-2"
        style={{
          float: 'right',
        }}
      >
        <select
          value={selectedType}
          onChange={(e) => {
            setSelectedType(e.target.value);
          }}
        >
          {productTypes.map((type) => (
            <option key={type.value} value={type.value}>{type.name}</option>
          ))}
        </select>
        <button
          type="button"
          className="ml-2 inline-flex items-center whitespace-nowrap rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-20"
          onClick={() => exportProductLevelTab(dates.from.toISODate(), dates.to.toISODate(), selectedType)}
        >
          <DownloadIcon className="-ml-0.5 mr-2 h-4 w-4" />
          Export
        </button>
      </div>
      <ReportDateRangeContext.Provider value={dates}>
        <DateRangeInput dates={dates} onChange={setDates} />
        {productExportCache && (
          <ProductLevelForm productExportCache={productExportCache} />
        )}
      </ReportDateRangeContext.Provider>
    </Tab.Panel>
  );
}
