import useSWR from "swr";
import { statefulRestfulFetcher } from "../utils/fetchers";

export default function useProductExportCache(sku) {
  const { data, error } = useSWR(
    sku && `/api/combo-sku/${sku}`,
    statefulRestfulFetcher
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
}
